import { frontendTypes } from '../types';

export const DatafieldColumns: frontendTypes.TableColumns = [
  {
    dataFieldId: 1,
    data_value: '1',
    id: 0,
    entityId: 1,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 2,
    data_value: '2',
    id: 0,
    entityId: 1,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 3,
    data_value: '3',
    id: 0,
    entityId: 1,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 4,
    data_value: '4',
    id: 0,
    entityId: 1,
    entityType: 'mock',
    clientId: 1,
  },
];

export const dataFieldForm: frontendTypes.Form = [
  {
    dataFieldId: 1,
    data_value: '{"row": 1, "position": 1, "required": false}',
    id: 0,
    entityId: 1,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 2,
    data_value: '{"row": 2, "position": 1, "required": false}',
    id: 0,
    entityId: 1,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 3,
    data_value: '{"row": 3, "position": 1, "required": false}',
    id: 0,
    entityId: 1,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 4,
    data_value: '{"row": 4, "position": 1, "required": false}',
    id: 0,
    entityId: 1,
    entityType: 'mock',
    clientId: 1,
  },
];

export const editDataFieldForm: frontendTypes.Form = [
  {
    dataFieldId: 1,
    data_value:
      '{"row": 1, "position": 1, "required": true, "disabled": false }',
    id: 0,
    entityId: 1,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 2,
    data_value:
      '{"row": 2, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityId: 1,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 3,
    data_value: '{"row": 3, "position": 1, "required": true, "disabled": true}',
    id: 0,
    entityId: 1,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 4,
    data_value:
      '{"row": 4, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 1,
    entityType: 'mock',
    clientId: 1,
  },
];

export const editEntityContactForm: frontendTypes.Form = [
  {
    dataFieldId: 57,
    data_value:
      '{"row": 2, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 58,
    data_value:
      '{"row": 2, "position": 2, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 59,
    data_value:
      '{"row": 3, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 60,
    data_value:
      '{"row": 4, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 61,
    data_value:
      '{"row": 4, "position": 2, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 62,
    data_value:
      '{"row": 5, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
];

export const insertEntityContactForm: frontendTypes.Form = [
  {
    dataFieldId: 57,
    data_value:
      '{"row": 1, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 58,
    data_value:
      '{"row": 1, "position": 2, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 59,
    data_value:
      '{"row": 2, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 60,
    data_value:
      '{"row": 3, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 61,
    data_value:
      '{"row": 3, "position": 2, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,

    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 62,
    data_value:
      '{"row": 4, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityType: 'mock',
    clientId: 1,
    entityId: 3,
  },
];

export const insertEntityClientForm: frontendTypes.Form = [
  {
    dataFieldId: 64,
    data_value:
      '{"row": 1, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 65,
    data_value:
      '{"row": 1, "position": 2, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 66,
    data_value:
      '{"row": 2, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 67,
    data_value:
      '{"row": 4, "position": 2, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 68,
    data_value:
      '{"row": 3, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,

    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 69,
    data_value:
      '{"row": 3, "position": 2, "required": false, "disabled": false}',
    id: 0,
    entityType: 'mock',
    clientId: 1,
    entityId: 3,
  },
];

export const editEntityClientForm: frontendTypes.Form = [
  {
    dataFieldId: 64,
    data_value:
      '{"row": 2, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 65,
    data_value:
      '{"row": 2, "position": 2, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 66,
    data_value:
      '{"row": 3, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 67,
    data_value:
      '{"row": 4, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 68,
    data_value:
      '{"row": 4, "position": 2, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,

    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 69,
    data_value:
      '{"row": 5, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityType: 'mock',
    clientId: 1,
    entityId: 3,
  },
];

// 74	project_manager
// 75	project_client
// 76	project_parent
// 77	project_start_date
// 78	project_end_date
// 79	project_members
// 80	project_description

export const insertEntityProjectForm: frontendTypes.Form = [
  {
    dataFieldId: 72,
    data_value:
      '{"row": 1, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 75,
    data_value:
      '{"row": 1, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 74,
    data_value:
      '{"row": 1, "position": 2, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 76,
    data_value:
      '{"row": 2, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 77,
    data_value:
      '{"row": 3, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 78,
    data_value:
      '{"row": 3, "position": 2, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,

    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 79,
    data_value:
      '{"row": 4, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityType: 'mock',
    clientId: 1,
    entityId: 3,
  },
  {
    dataFieldId: 80,
    data_value:
      '{"row": 5, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityType: 'mock',
    clientId: 1,
    entityId: 3,
  },
];

export const editEntityProjectForm: frontendTypes.Form = [
  {
    dataFieldId: 72,
    data_value:
      '{"row": 1, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 75,
    data_value:
      '{"row": 1, "position": 1, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 74,
    data_value:
      '{"row": 1, "position": 2, "required": true, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 76,
    data_value:
      '{"row": 2, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 77,
    data_value:
      '{"row": 3, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,
    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 78,
    data_value:
      '{"row": 3, "position": 2, "required": false, "disabled": false}',
    id: 0,
    entityId: 3,

    entityType: 'mock',
    clientId: 1,
  },
  {
    dataFieldId: 79,
    data_value:
      '{"row": 4, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityType: 'mock',
    clientId: 1,
    entityId: 3,
  },
  {
    dataFieldId: 80,
    data_value:
      '{"row": 5, "position": 1, "required": false, "disabled": false}',
    id: 0,
    entityType: 'mock',
    clientId: 1,
    entityId: 3,
  },
];
