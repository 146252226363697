import { FocusMonitor } from '@angular/cdk/a11y';
import { AsyncPipe, JsonPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  booleanAttribute,
  computed,
  effect,
  forwardRef,
  inject,
  input,
  model,
  signal,
  untracked,
  viewChild,
  Input,
  HostBinding,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormFieldControl,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';

class Phone {
  constructor(
    public country: string,
    public subscriber: string,
  ) {}
}

@Component({
  selector: 'custom-phone-input',
  styleUrl: 'phone.component.scss',
  templateUrl: 'phone.component.html',
  host: {
    '[class.custom-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
  providers: [{ provide: MatFormFieldControl, useExisting: PhoneInput }],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneInput
  implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy
{
  static nextId = 0;
  readonly countryInput = viewChild.required<HTMLInputElement>('country');
  readonly subscriberInput = viewChild.required<HTMLInputElement>('subscriber');
  ngControl = inject(NgControl, { optional: true, self: true });
  readonly parts: FormGroup<{
    country: FormControl<string | null>;
    subscriber: FormControl<string | null>;
  }>;
  readonly stateChanges = new Subject<void>();
  readonly touched = signal(false);
  readonly controlType = 'custom-phone-input';
  readonly id = `custom-phone-input-${PhoneInput.nextId++}`;
  readonly _userAriaDescribedBy = input<string>('', {
    alias: 'aria-describedby',
  });
  readonly _placeholder = input<string>('', { alias: 'placeholder' });
  readonly _required = input<boolean, unknown>(false, {
    alias: 'required',
    transform: booleanAttribute,
  });
  readonly _disabledByInput = input<boolean, unknown>(false, {
    alias: 'disabled',
    transform: booleanAttribute,
  });
  readonly _value = model<Phone | null>(null, { alias: 'value' });
  onChange = (_: any) => {};
  onTouched = () => {};

  protected readonly _formField = inject(MAT_FORM_FIELD, {
    optional: true,
  });

  private readonly _focused = signal(false);
  private readonly _disabledByCva = signal(false);
  private readonly _disabled = computed(
    () => this._disabledByInput() || this._disabledByCva(),
  );
  private readonly _focusMonitor = inject(FocusMonitor);
  private readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  get focused(): boolean {
    return this._focused();
  }

  get empty() {
    const {
      value: { country, subscriber },
    } = this.parts;

    return !country && !subscriber;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get userAriaDescribedBy() {
    return this._userAriaDescribedBy();
  }

  get placeholder(): string {
    return this._placeholder();
  }

  get required(): boolean {
    return this._required();
  }

  get disabled(): boolean {
    return this._disabled();
  }

  get value(): string | null {
    if (!this._value() || !this._value()?.subscriber) {
      return null;
    }
    const phoneNumber = this._value()?.country
      ? this._value()?.country! + '-' + this._value()?.subscriber!
      : this._value()?.subscriber!;
    return phoneNumber;
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched();
  }
  constructor() {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.parts = inject(FormBuilder).group({
      country: ['', [Validators.maxLength(6), Validators.pattern('^[0-9]*$')]],

      subscriber: [
        '',
        [Validators.maxLength(13), Validators.pattern('^[0-9]*$')],
      ],
    });

    effect(() => {
      // Read signals to trigger effect.
      this._placeholder();
      this._required();
      this._disabled();
      this._focused();
      // Propagate state changes.
      untracked(() => this.stateChanges.next());
    });

    effect(() => {
      if (this._disabled()) {
        untracked(() => this.parts.disable());
      } else {
        untracked(() => this.parts.enable());
      }
    });

    effect(() => {
      const value = this._value() || new Phone('', '');
      untracked(() => this.parts.setValue(value));
    });

    this.parts.statusChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      this.stateChanges.next();
    });

    this.parts.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      const tel = this.parts.valid
        ? new Phone(
            this.parts.value.country || '',
            this.parts.value.subscriber || '',
          )
        : null;
      this._updateValue(tel);
    });
  }

  getMaxLength(control: AbstractControl): number | null {
    if (control && control.validator) {
      const validator = control.validator({} as any);

      if (validator && validator['maxlength']) {
        return validator['maxlength'].requiredLength;
      }
    }
    return null;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn() {
    if (!this._focused()) {
      this._focused.set(true);
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched.set(true);
      this._focused.set(false);
      this.onTouched();
    }
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement,
  ): void {
    if (!control.value) {
      return;
    }
    if (
      !control.errors &&
      nextElement &&
      control.value?.length === this.getMaxLength(control)
    ) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (!control.value) {
      return;
    }
    if (control.value?.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.custom-phone-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this._focusMonitor.focusVia(this.subscriberInput(), 'program');
  }

  splitTel(inputTel: string): [string, string] {
    // Split the input string on the first occurrence of '-'
    const parts = inputTel.split('-', 2);

    // Assign parts to first and second strings based on the presence of '-'
    if (parts.length === 2) {
      return [parts[0], parts[1]];
    } else {
      return ['', parts[0]];
    }
  }

  writeValue(tel: Phone | string | null): void {
    // If `tel` is a string, convert it to a `Phone` instance
    if (typeof tel === 'string') {
      const parts = this.splitTel(tel);

      tel = new Phone(parts[0], parts[1]);
    }

    // Update the internal FormGroup `parts` with the `note` content
    if (
      tel instanceof Phone &&
      !(tel.country == null && tel.subscriber == null)
    ) {
      this.parts.setValue(
        { country: tel.country, subscriber: tel.subscriber },
        { emitEvent: false },
      );
      this._updateValue(tel);
    } else {
      this.parts.reset();
      this._updateValue(null);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabledByCva.set(isDisabled);
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }

  private _updateValue(tel: Phone | null) {
    const current = this._value();
    if (
      tel === current ||
      (tel?.country === current?.country &&
        tel?.subscriber === current?.subscriber)
    ) {
      return;
    }
    this._value.set(tel);
  }
}
