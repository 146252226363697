import { Component, Input, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormBuilder,
} from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
/**  [startAt]="formField.value | date : 'mediumDate'"  */

import { frontendTypes } from '../../../assets/types';
import { FunctionsService } from '../../../services/functions/functions.service';
import { DataFieldComponent } from '../data-field/data-field.component';

import { typeGuards } from '../../../services/functions/typeguards/typeguards';
import { backendTypes } from '../../../assets/types';

const SYSTEM_IDENTITIES = backendTypes.SYSTEM_IDENTITIES;
const SYSTEM_SETTINGS = frontendTypes.SYSTEM_SETTINGS;

@Component({
  selector: 'app-input-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    TranslateModule,
    DataFieldComponent,
  ],

  templateUrl: './input-form.component.html',
  styleUrl: './input-form.component.scss',
})
export class InputFormComponent {
  functions = inject(FunctionsService);
  translate = inject(TranslateService);
  formBuilder = new FormBuilder();
  typeGuards = typeGuards;

  data = signal<frontendTypes.Entity | frontendTypes.TimeReg | null>(null);
  private _formDefinition: frontendTypes.Form | frontendTypes.TimeForm | null =
    null;

  parentForm?: FormGroup;
  formGroupInitialized = false;
  initMessage = 'Initializing';

  gridData: any[][] = [];
  maxRow: number = 0;
  maxOrder: number = 0;

  @Input()
  set inputForm(input: frontendTypes.Form | frontendTypes.TimeForm | null) {
    if (!input) {
      return;
    }
    this._formDefinition = input;

    if (this.parentForm) {
      this.prepareAndCreateForms();
    }
  }

  @Input()
  set inputData(input: frontendTypes.Entity | frontendTypes.TimeReg | null) {
    if (!input) {
      return;
    }
    this.data.set(input);
  }

  @Input()
  set inputParentForm(input: FormGroup | null) {
    if (!input) {
      return;
    }

    this.parentForm = input;

    if (this._formDefinition) {
      this.prepareAndCreateForms();
    }
  }
  prepareAndCreateForms() {
    if (!this.parentForm || !this._formDefinition) {
      this.initMessage = 'No form available';
      return;
    }

    let maxRow = 0;
    let maxOrder = SYSTEM_SETTINGS.default_form_row_length;

    // Step 1: Parse fields, calculate maxRow and maxOrder, and initialize controls in a single loop
    const parsedFields = this._formDefinition.map((field, index) => {
      try {
        let data;
        try {
          data = JSON.parse(field.data_value);
        } catch (parseError) {}

        // Fallback if parsed data is not an object
        if (typeof data !== 'object' || data === null) {
          data = {}; // Set data to an empty object as a fallback
        }

        const row = typeof data.row === 'number' ? data.row : index;
        const position =
          typeof data.position === 'number' ? data.position : index;
        const disabled =
          typeof data.disabled === 'boolean' ? data.disabled : false;
        const required =
          typeof data.required === 'boolean' ? data.required : false;

        maxRow = Math.max(maxRow, row);
        maxOrder = Math.max(maxOrder, position);

        // Initialize form control
        if (!this.parentForm!.contains(field.dataFieldId.toString())) {
          const dataField = this.functions.getDataField(field.dataFieldId);
          const validators = [];

          if (required) {
            validators.push(Validators.required);
          }

          if (dataField?.dataType === 'email') {
            validators.push(Validators.email);
          }

          let initialValue: string | number | null =
            this.data()?.find((d) => d.dataFieldId === field.dataFieldId)
              ?.data_value || null;

          // value of data_value in backend is a string representation of the selected entity Id
          if (
            this.functions.getDataType(field.dataFieldId) === 'select_list' ||
            this.functions.getDataType(field.dataFieldId) === 'user'
          ) {
            initialValue = Number(initialValue);
          }

          // value of data_value in backend for list_select_list is a string representation of the array of selected entity Ids
          if (
            (this.functions.getDataType(field.dataFieldId) ===
              'list_select_list' ||
              this.functions.getDataType(field.dataFieldId) === 'user_list') &&
            typeof initialValue === 'string'
          ) {
            initialValue = JSON.parse(initialValue);
          }

          const control = this.formBuilder.control(
            {
              value: initialValue,
              disabled: disabled,
            },
            validators.length > 0 ? validators : null,
          );

          this.parentForm!.addControl(field.dataFieldId.toString(), control);
        } else {
          console.warn(
            `Control with ID ${field.dataFieldId} already exists in the parent form.`,
          );
        }

        return { ...field, row, position, disabled, required };
      } catch (error) {
        console.warn('error in initalizing: ', error);
        return {
          ...field,
          row: index,
          position: index,
          disabled: false,
          required: false,
        };
      }
    });

    // Update maxRow and maxOrder based on parsed fields
    this.maxRow = maxRow;
    this.maxOrder = maxOrder;

    // Step 2: Initialize gridData with dimensions
    this.gridData = Array.from({ length: this.maxRow + 1 }, () =>
      Array(this.maxOrder + 1).fill(null),
    );

    // Step 3: Populate gridData, resolving conflicts
    parsedFields.forEach((field) => {
      let { row, position } = field;

      // Ensure the row array exists in gridData before accessing
      while (this.gridData[row] && this.gridData[row][position] !== null) {
        position++;
        if (position > this.maxOrder) {
          row++;
          position = 0;
          // Ensure new row exists
          if (!this.gridData[row]) {
            this.gridData[row] = Array(this.maxOrder + 1).fill(null);
          }
        }
      }

      // Ensure the current row exists in gridData before assigning
      if (!this.gridData[row]) {
        this.gridData[row] = Array(this.maxOrder + 1).fill(null);
      }

      // Place the field in the determined row and position
      this.gridData[row][position] = field;
    });

    this.formGroupInitialized = true;
  }

  getFieldLabel(dataField: any) {
    return this.typeGuards.isSqlDataField(dataField)
      ? dataField.label
      : `SYSTEM_LABELS.${dataField.name}`;
  }
}
