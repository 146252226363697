import { Injectable, inject, Signal, computed } from '@angular/core';
import { filter } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import { Router, NavigationEnd } from '@angular/router';
import { backendTypes, frontendTypes } from '../../assets/types';
import { SettingsService } from '../settings and config/settings.service';

// import { ConfigService } from '../settings and config/config.service';

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  private settings = inject(SettingsService);
  // private config = inject(ConfigService);
  private router = inject(Router);
  private _currentUrl: Signal<NavigationEnd | undefined> = toSignal(
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    ),
  );

  private dataFields = this.settings.dataFields;

  currentUrl = computed(() => this._currentUrl()?.urlAfterRedirects || '');

  getDataField(id: number): backendTypes.sqlDataField | null {
    if (!id) {
      return null;
    }

    return this.dataFields()?.find((field) => field.id === id) ?? null;
  }

  getDataType(id: number): string | null {
    if (!id) {
      return null;
    }

    return (
      this.dataFields()?.find((field) => field.id === id)?.dataType ?? null
    );
  }
}
