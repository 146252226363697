import {
  Component,
  Input,
  inject,
  signal,
  computed,
  OnChanges,
  SimpleChanges,
  WritableSignal,
  effect,
  forwardRef,
  Signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { map } from 'rxjs';

import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { CheckOverflowDirective } from '../../../directives/check-overflow.directive';

import { TranslateModule } from '@ngx-translate/core';

import { FunctionsService } from '../../../services/functions/functions.service';
import { typeGuards } from '../../../services/functions/typeguards/typeguards';
import { backendTypes, frontendTypes } from '../../../assets/types';
import { DataService } from '../../../services/dataservices/data.service';
import { SettingsService } from '../../../services/settings and config/settings.service';
import { MatIconModule } from '@angular/material/icon';

import { PhoneInput } from '../input/phone/phone.component';
import { DateInput } from '../input/date/date.component';
import { NotesInput } from '../input/notes/notes.component';
import { ConfigService } from '../../../services/settings and config/config.service';

@Component({
  selector: 'app-data-field',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CheckOverflowDirective,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatSelectModule,
    forwardRef(() => [PhoneInput, NotesInput, DateInput]),
  ],
  templateUrl: './data-field.component.html',
  styleUrl: './data-field.component.scss',
})
export class DataFieldComponent implements OnChanges {
  functions = inject(FunctionsService);
  translate = inject(TranslateModule);
  config = inject(ConfigService);
  dataService = inject(DataService);
  settings = inject(SettingsService);
  typeGuards = typeGuards;
  SYSTEM_IDENTITIES = backendTypes.SYSTEM_IDENTITIES;

  @Input() parentForm?: FormGroup | null | undefined;

  inputEnabled = true;
  dataFieldId = signal('');
  _data?: WritableSignal<frontendTypes.Entity | frontendTypes.TimeReg>;

  dataTypes = this.config.dataTypes;
  userList = this.settings.userList;
  selectedUsers: Signal<backendTypes.sqlUserListItem[] | null> = computed(
    () => {
      const dataField = this.dataField();
      const value = this.value();
      const userList = this.userList();

      // Return null if conditions aren't met
      if (
        !dataField ||
        (dataField.dataType !== 'user' && dataField.dataType !== 'user_list') ||
        !value ||
        !userList
      ) {
        return null;
      }

      const users: number[] =
        dataField.dataType === 'user_list'
          ? JSON.parse(value)
          : [Number(value)];

      if (!Array.isArray(users)) {
        return null;
      }
      const result = users
        .map((user) => userList!.find((u) => u.id === user))
        .filter(Boolean) as backendTypes.sqlUserListItem[];

      return result;
    },
  );

  dataField = computed(() => {
    return this.dataFieldId()
      ? this.functions.getDataField(Number(this.dataFieldId()))
      : null;
  });

  value = computed(() => {
    if (!this.dataFieldId()) {
      return null;
    }
    if (!this._data) {
      return null;
    }
    const values =
      this._data()?.filter(
        (d) => d.dataFieldId.toString() === this.dataFieldId!(),
      ) ?? null;
    if (!values || values.length == 0) {
      return null;
    }
    return values[0].data_value;
  });

  context: 'table-header' | 'table-cell' | 'form' | 'default' = 'default';

  hasFormControl = false;
  metaData = computed(() =>
    this.dataField()?.metaData ? JSON.parse(this.dataField()!.metaData!) : null,
  );

  list = computed(() => {
    if (
      (this.dataField()!.dataType !== 'list_select_list' &&
        this.dataField()!.dataType !== 'select_list') ||
      !this.value()
    ) {
      return null;
    }
    const entities =
      this.dataField()!.dataType === 'list_select_list'
        ? JSON.parse(this.value()!)
        : [Number(this.value())];

    if (!Array.isArray(entities)) {
      return null;
    }

    const filterValue: frontendTypes.EntityFilterCondition[] = entities.map(
      (entity: number) => ({
        key: 'entityId',
        value: entity,
        operator: 'equals',
      }),
    );

    const listEntities = this.dataService.getEntitiesByFilter(
      filterValue,
      undefined,
      'OR',
    )();
    return listEntities;
  });

  selectList = computed(() => {
    if (
      this.dataField()!.dataType !== 'select_list' &&
      this.dataField()!.dataType !== 'list_select_list'
    ) {
      return null;
    }
    if (!this.metaData()) {
      return null;
    }
    return this.dataService.getEntitiesByFilter(this.metaData().filter)();
  });

  _createLabelForList(entity: frontendTypes.Entity): string {
    if (!entity) return '';

    if (Array.isArray(this.metaData().labelUse)) {
      let label = '';

      this.metaData().labelUse.forEach((fieldId: number) => {
        const dataField = entity.find((d) => d.dataFieldId === fieldId);

        if (dataField) {
          label = label.concat(' ', dataField.data_value);
        }
      });

      return label.trim();
    }

    return entity[0].entityId.toString();
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['parentForm']) {
      this.updateHasFormControl();
    }
  }

  private updateHasFormControl() {
    // Check if the form control exists in parentForm

    if (!this.dataFieldId()) {
      return;
    }

    this.hasFormControl = !!(
      this.parentForm && this.parentForm.get(this.dataFieldId()!)
    );

    if (!this.hasFormControl) {
    }
  }

  @Input()
  set fieldId(input: string) {
    if (input) {
      this.dataFieldId.set(input);
    }
  }

  @Input()
  set inputField(input: boolean) {
    if (input) {
      this.inputEnabled = input;
    }
  }

  @Input()
  set data(input: frontendTypes.Entity | frontendTypes.TimeReg | null) {
    if (input) {
      this._data = signal(input);
    }
  }

  @Input()
  set template(input: 'table-header' | 'table-cell' | 'form' | 'default') {
    if (input) {
      this.context = input;
    }
  }
}
