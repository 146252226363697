import { Component, inject } from '@angular/core';
import { RouterOutlet, RouterLink } from '@angular/router';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

import { FunctionsService } from '../../services/functions/functions.service';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatListModule,
    RouterLink,
    RouterOutlet,
    TranslateModule,
    MatButtonModule,
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss',
})
export class AdminComponent {
  functions = inject(FunctionsService);
  translate = inject(TranslateService);
  currentUrl = this.functions.currentUrl;

  adminButtonLinks = [
    { url: '/dashboard/admin/users', label: 'Users' },
    { url: '/dashboard/admin/datafields', label: 'Datafields' },
    { url: '/dashboard/admin/forms', label: 'Forms' },
  ];
}
