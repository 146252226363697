import { Component, inject, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CheckOverflowDirective } from '../../../directives/check-overflow.directive';
import { DataService } from '../../../services/dataservices/data.service';
import { ConfigService } from '../../../services/settings and config/config.service';

import { DatafieldColumns } from '../../../assets/model/data.model';
import { AdddatafieldComponent } from './adddatafield/adddatafield.component';
import { CloseComponent } from '../../ui-components/buttons/close/close.component';
import { EditComponent } from '../../ui-components/buttons/edit/edit.component';
import { SettingsService } from '../../../services/settings and config/settings.service';
import { ConfirmDialogComponent } from '../../ui-components/confirm-dialog/confirm-dialog.component';
import { EditdatafieldComponent } from './editdatafield/editdatafield.component';
import { FunctionsService } from '../../../services/functions/functions.service';
import { frontendTypes } from '../../../assets/types';
import { backendTypes } from '../../../assets/types';

import { DynamicTableComponent } from '../../ui-components/dynamic-table/dynamic-table.component';

const SYSTEM_IDENTITIES = backendTypes.SYSTEM_IDENTITIES;

@Component({
  selector: 'app-datafields',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,

    CommonModule,
    ConfirmDialogComponent,
    CloseComponent,
    EditComponent,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    CheckOverflowDirective,
    DynamicTableComponent,
  ],
  templateUrl: './datafields.component.html',
  styleUrl: './datafields.component.scss',
})
export class DatafieldsComponent {
  translate = inject(TranslateService);
  dataService = inject(DataService);
  config = inject(ConfigService);
  tableFilter: frontendTypes.EntityFilterCondition = {
    key: 'entityId',
    value: SYSTEM_IDENTITIES.table_columns_data_fields,
    operator: 'equals',
  };

  tableColumns = this.dataService.getEntitiesByFilter([this.tableFilter]);
  dialog = inject(MatDialog);
  settings = inject(SettingsService);
  functions = inject(FunctionsService);

  // map datafields to generic entity with dataId / data_value pairs
  //  adjust when datafield def changes

  tableData = computed(() =>
    this.settings.dataFields()
      ? this.settings
          .dataFields()!
          .map((datafield: backendTypes.sqlDataField) =>
            this.convert(datafield),
          )
      : null,
  );

  // convert datafieldData naar generiek data format

  convert(dataFieldData: backendTypes.sqlDataField) {
    const convertedDataField: backendTypes.sqlEntity = [
      {
        id: dataFieldData.id,
        entityId: dataFieldData.id,
        entityType: 'data_field',
        data_value: dataFieldData.name,
        dataFieldId: SYSTEM_IDENTITIES.data_field_name,
        clientId: dataFieldData.clientId,
      },
      {
        id: dataFieldData.id,
        entityId: dataFieldData.id,
        entityType: 'data_field',
        data_value: dataFieldData.description,
        dataFieldId: SYSTEM_IDENTITIES.data_field_description,
        clientId: dataFieldData.clientId,
      },
      {
        id: dataFieldData.id,
        entityId: dataFieldData.id,
        entityType: 'data_field',
        data_value: dataFieldData.dataType,
        dataFieldId: SYSTEM_IDENTITIES.data_field_datatype,
        clientId: dataFieldData.clientId,
      },
      {
        id: dataFieldData.id,
        entityId: dataFieldData.id,
        entityType: 'data_field',
        data_value: dataFieldData.label,
        dataFieldId: SYSTEM_IDENTITIES.data_field_label,
        clientId: dataFieldData.clientId,
      },
    ];

    return convertedDataField;
  }

  removeRow = (id: string) => {
    if (
      this.functions.getDataField(Number(id))?.clientId ==
      SYSTEM_IDENTITIES.system_client_id
    ) {
      console.log('no permission to edit system fields');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: 'deleteWarning_dataField',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.dataService.dbDeleteDataField(Number(id)).subscribe({
          next: (response) => {
            if (!response) {
              console.error('delete operation failed');
            }
          },
          error: (err) => {
            console.error('Delete failed:', err);
          },
        });
      } else {
        console.log('delete datafield canceled');
      }
    });

    console.log('datafield with id: ' + id + ' removed');
  };

  addRow = () => {
    const dialogRef = this.dialog.open(AdddatafieldComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  };

  editRow = (id: string) => {
    if (
      this.functions.getDataField(Number(id))?.clientId ==
      SYSTEM_IDENTITIES.system_client_id
    ) {
      console.log('no permission to edit system fields');
      return;
    }

    console.log('datafield with id: ' + id + ' edited');
    const dataField = this.functions.getDataField(Number(id));
    const dialogRef = this.dialog.open(EditdatafieldComponent, {
      data: dataField ? this.convert(dataField) : null,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  };
}
