import {
  Injectable,
  inject,
  signal,
  computed,
  Signal,
  effect,
} from '@angular/core';

import { forkJoin, Subscription } from 'rxjs';

import { ConfigService } from './config.service';
import { backendTypes } from '../../assets/types';
import { typeGuards } from '../functions/typeguards/typeguards';
import { HttpService } from '../httpservices/http.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private config = inject(ConfigService);
  private http = inject(HttpService);
  private _dataFields = signal<backendTypes.sqlDataField[] | null>(null);
  private _userList = signal<backendTypes.sqlUserListItem[] | null>(null);
  private currentRefreshSubscription: Subscription | null;
  listCollection = signal<backendTypes.sqlEntityType[] | null>(null);

  dataFields: Signal<backendTypes.sqlDataField[] | null> = computed(() => {
    return this._dataFields() ? this._dataFields() : null;
  });

  userList: Signal<backendTypes.sqlUserListItem[] | null> = computed(() => {
    return this._userList() ? this._userList() : null;
  });

  fireRefreshSettings = signal(0);

  setDataField(dataField: backendTypes.sqlDataField) {
    if (!this._dataFields()) {
      // Initialize _dataFields with the first item if it's currently null
      this._dataFields.set([dataField]);
    } else {
      // Check if the dataField with the same ID already exists
      const exists = this._dataFields()?.some((f) => f.id === dataField.id);

      if (exists) {
        // Update the existing dataField if it exists
        this._dataFields.update((fields) =>
          fields!.map((f) => (f.id === dataField.id ? dataField : f)),
        );
      } else {
        // Add the new dataField if it doesn't exist
        this._dataFields.update((fields) => [...fields!, dataField]);
      }
    }
  }
  deleteDataField(id: number) {
    if (!this._dataFields()) {
      return;
    }

    this._dataFields.update((fields) => fields!.filter((f) => f.id !== id));
  }

  constructor() {
    this.currentRefreshSubscription = null;

    effect(() => {
      if (this.currentRefreshSubscription) {
        this.currentRefreshSubscription.unsubscribe();
      }

      const timers = this.config.refreshTimers();
      if (timers.length > 0) {
        this.currentRefreshSubscription = timers[0].refreshTimer.subscribe(() =>
          this.updateSettings(),
        );
      }
    });

    // Sets up an effect to call fireRefreshSettings and immediately update settings once
    effect(() => {
      this.fireRefreshSettings();
      this.updateSettings();
    });
  }

  updateSettings(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Use forkJoin to wait for all observables to complete
      forkJoin([
        this.http.fetchData({
          request: 'get_data_fields',
          requestType: 'read',
        }),
        this.http.fetchData({
          request: 'get_user_list',
          requestType: 'read',
        }),
      ]).subscribe({
        next: ([dataFields, users]) => {
          try {
            // Process the  data fields
            if (dataFields && typeGuards.isSqlDataFieldArray(dataFields)) {
              this._dataFields.set(dataFields);
            }

            // Process userlist
            if (users && typeGuards.isSqlUserList(users)) {
              this._userList.set(users);
            }

            // Resolve the promise once all data is set successfully
            resolve();
          } catch (error) {
            console.error('Error processing fetched data:', error);
            reject(error);
          }
        },
        error: (error) => {
          // Handle any error that occurred in any of the observables
          console.error('Error fetching settings data:', error);
          reject(error);
        },
      });
    });
  }

  ngOnDestroy() {
    if (this.currentRefreshSubscription) {
      this.currentRefreshSubscription.unsubscribe();
    }
  }
}
