import { Component, inject, signal, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs';

import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AuthService } from '../../services/userservices/auth.service';

@Component({
  selector: 'app-loggedin',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIcon,
  ],
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit, OnDestroy {
  auth = inject(AuthService);
  router = inject(Router);

  title = 'Login Status';

  dots = '';
  index = 0;

  loggedinstatus = signal(false);
  subscription = this.auth.user$
    .pipe(
      filter((user) => user !== undefined), // Ensure we only proceed with defined user objects
      map((user) => user !== null), // Map the user object to a boolean: true if logged in, false if not
    )
    .subscribe((isLoggedIn) => {
      this.loggedinstatus.set(isLoggedIn);
    });

  constructor() {
    setInterval(() => {
      const dotsArray = ['', '.', '..', '...', '....'];
      this.dots = dotsArray[(this.index + 1) % dotsArray.length];
      this.index++;
    }, 1000);
  }

  ngOnInit(): void {
    // Handle the login completion based on the URL and use `then` instead of `subscribe`
    this.auth
      .completeLogin(window.location.href)
      .then(() => {
        console.log('Login complete');
        // Navigate to the dashboard or update the UI as needed
      })
      .catch((error) => {
        // Handle errors, possibly redirect to the login page again
        this.router.navigate(['login']);
      });
  }

  ngOnDestroy(): void {
    // Clean up the subscription when the component is destroyed
    this.subscription.unsubscribe();
  }
}
