import { Component, inject, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { InputFormComponent } from '../../../ui-components/input-form/input-form.component';
import { dataFieldForm } from '../../../../assets/model/data.model';
import { DataService } from '../../../../services/dataservices/data.service';
import { backendTypes, frontendTypes } from '../../../../assets/types';
import { typeGuards } from '../../../../services/functions/typeguards/typeguards';
import { atLeastOne } from '../../../../assets/shared/validators';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

const SYSTEM_IDENTITIES = backendTypes.SYSTEM_IDENTITIES;

const nonEmptyValidator = Validators.required;

// , { validators: atLeastOne(nonEmptyValidator) }
@Component({
  selector: 'app-adddatafield',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    InputFormComponent,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    TranslateModule,
    CommonModule,
  ],
  templateUrl: './adddatafield.component.html',
  styleUrl: './adddatafield.component.scss',
})
export class AdddatafieldComponent {
  // initializing form
  form = new FormGroup({}, { validators: atLeastOne(nonEmptyValidator) });
  formFields = signal(dataFieldForm);

  dataService = inject(DataService);
  dialog = inject(MatDialogRef);
  translate = inject(TranslateService);

  onSubmit() {
    let input = this.form.getRawValue();

    console.log(input);

    let dataField: backendTypes.sqlDataField = {
      id: 0,
      name: input[
        SYSTEM_IDENTITIES.data_field_name.toString() as keyof typeof input
      ],
      label:
        input[
          SYSTEM_IDENTITIES.data_field_label.toString() as keyof typeof input
        ],
      description:
        input[
          SYSTEM_IDENTITIES.data_field_description.toString() as keyof typeof input
        ],
      dataType:
        input[
          SYSTEM_IDENTITIES.data_field_datatype.toString() as keyof typeof input
        ],
      clientId: 0,
      metaData: null,
    };

    console.log('datafield: ', dataField);

    if (typeGuards.isSqlDataField(dataField)) {
      this.dataService.dbInsertDataField(dataField).subscribe({
        next: (response) => {
          if (response) {
            this.dialog.close(true);
          } else {
            console.error('Insert datafield failed');
          }
        },
        error: (err) => {
          console.error('Save failed:', err);
        },
      });
    }

    console.log('no valid datafield');
    return true;
  }
}
