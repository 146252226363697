import { Component, inject, computed, signal } from '@angular/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { LanguageService } from '../../../services/language/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-langselector',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
  ],

  templateUrl: './langselector.component.html',
  styleUrl: './langselector.component.scss',
})
export class LangselectorComponent {
  languageService = inject(LanguageService);
  translate = inject(TranslateService);
  supportedLanguages = this.languageService.supportedLanguages;
  translateLanguage = signal('en');
  selectedLanguage = computed(
    () =>
      this.supportedLanguages.find((l) => l.code == this.translateLanguage()) ||
      this.supportedLanguages[0],
  );

  constructor() {
    this.translate.onLangChange.subscribe((event) => {
      this.translateLanguage.set(event.lang); // Ensure selected language is updated when TranslateService changes the language
    });
  }

  changeLanguage = this.languageService.changeLanguage;
}
