import { Component, inject, computed, signal, Inject } from '@angular/core';

import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  MatDialogModule,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { InputFormComponent } from '../../ui-components/input-form/input-form.component';

import { backendTypes, httpTypes } from '../../../assets/types';
import { typeGuards } from '../../../services/functions/typeguards/typeguards';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DataService } from '../../../services/dataservices/data.service';
import { atLeastOne } from '../../../assets/shared/validators';

const SYSTEM_IDENTITIES = backendTypes.SYSTEM_IDENTITIES;

const nonEmptyValidator = Validators.required;

@Component({
  selector: 'app-addentity',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    InputFormComponent,
    FormsModule,
    ReactiveFormsModule,

    TranslateModule,
  ],
  templateUrl: './add-entity.component.html',
  styleUrl: './add-entity.component.scss',
})
export class AddEntityComponent {
  form = new FormGroup({}, { validators: atLeastOne(nonEmptyValidator) });
  dataService = inject(DataService);
  dialog = inject(MatDialogRef);
  translate = inject(TranslateService);

  constructor(
    public dialogRef: MatDialogRef<AddEntityComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityType: string;
      formTitle: string;
      form: backendTypes.sqlEntity;
      crudType: 'insert' | 'update';
      entity: backendTypes.sqlEntity | null;
    },
  ) {
    console.log('data in add-entity component: ' + JSON.stringify(this.data));
  }

  _dataUnchanged(newEntityValues: Record<string, string>) {
    return Object.entries(newEntityValues).every(([key, value]) => {
      this.data.entity!.some(
        (item) =>
          item.dataFieldId.toString() === key && item.data_value === value,
      );
    });
  }

  _convertInputToDataArray(
    input: Record<string, string>,
  ): httpTypes.DataArray[] {
    const array: httpTypes.DataArray[] = Object.entries(input).map(
      ([key, value]) => ({
        dataFieldId: Number(key),
        data_value: typeof value === 'string' ? value : JSON.stringify(value),
      }),
    );

    return array;
  }

  onSubmit() {
    console.log(this.form.getRawValue());
    let input: Record<string, string> = this.form.getRawValue();

    // ************* add entity

    if (this.data.crudType === 'insert') {
      const entity = {
        entityType: this.data.entityType,
        dataArray: this._convertInputToDataArray(input),
      };

      this.dataService.dbInsertEntity(entity).subscribe({
        next: (response) => {
          if (response) {
            this.dialog.close(true);
          } else {
            console.error('Insert entity failed');
          }
        },
        error: (err) => {
          console.error('Save failed:', err);
        },
      });
      return;
    }

    if (this.data.crudType === 'update' && this.data.entity) {
      if (this._dataUnchanged(input)) {
        return;
      }
      const entity = {
        id: this.data.entity[0].entityId,
        entityType: this.data.entityType,
        dataArray: this._convertInputToDataArray(input),
      };

      this.dataService.dbUpdateEntity(entity).subscribe({
        next: (response) => {
          if (response) {
            this.dialog.close(true);
          } else {
            console.error('Update dataField failed');
          }
        },
        error: (err) => {
          console.error('Save failed:', err);
        },
      });
      return;
    }

    console.log('no valid insert or update');
    return;
  }
}
