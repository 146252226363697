<h2 mat-dialog-title>{{ "CONFIRM_DIALOGUE.confirm" | translate }}</h2>
<mat-dialog-content>
  <p>
    {{
      warningMessage ? ("CONFIRM_DIALOGUE." + warningMessage | translate) : ""
    }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="cancel-button" mat-button [mat-dialog-close]>Cancel</button>
  <button class="delete-button" mat-button [mat-dialog-close]="true">
    Delete
  </button>
</mat-dialog-actions>
