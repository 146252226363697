<ng-container *ngIf="dataFieldId() && dataField()">
  <!-- *************** DEFAULT DATA FIELD ******************** -->

  <ng-container *ngIf="context === 'default'">
    <span>{{ value() }}</span>
  </ng-container>

  <!-- *************** TABLE HEADER ******************** -->

  <ng-container *ngIf="context === 'table-header'">
    <span
      *ngIf="
        dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id;
        else clientField
      "
    >
      {{ "SYSTEM_LABELS." + dataField()!.name | translate }}
    </span>
    <ng-template #clientField>
      <span>{{ dataField()!.label }}</span>
    </ng-template>
  </ng-container>

  <!-- *************** TABLE CELL ******************** checkOverflow="{{ value() }} " -->

  <ng-container *ngIf="context === 'table-cell'">
    <ng-container *ngIf="dataField()!.dataType === 'date'">
      <span>{{ value() | date: "mediumDate" }}</span>
    </ng-container>

    <ng-container *ngIf="dataField()!.dataType === 'string'">
      <span>{{ value() }}</span>
    </ng-container>

    <ng-container *ngIf="dataField()!.dataType === 'select_list'">
      <ng-container *ngFor="let item of list(); let i = index; let last = last">
        <span id="{{ 'entity' + item[0].entityId }}">
          {{ _createLabelForList(item) }}
        </span>
        <span *ngIf="!last">, </span>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="dataField()!.dataType === 'user'">
      <ng-container
        *ngFor="let item of selectedUsers(); let i = index; let last = last"
      >
        <span id="{{ 'user' + item.id }}">
          {{ item?.alias || "" }}
        </span>
        <span *ngIf="!last">, </span>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="dataField()!.dataType === 'user_list'">
      <ng-container
        *ngFor="let item of selectedUsers(); let i = index; let last = last"
      >
        <span id="{{ 'user' + item.id }}">
          {{ item?.alias || "" }}
        </span>
        <span *ngIf="!last">, </span>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="dataField()!.dataType === 'list_select_list'">
      <ng-container *ngFor="let item of list(); let i = index; let last = last">
        <span id="{{ 'entity' + item[0].entityId }}">
          {{ _createLabelForList(item) }}
        </span>
        <span *ngIf="!last">, </span>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="dataField()!.dataType === 'phone'">
      <span>{{ value() }}</span></ng-container
    >

    <ng-container *ngIf="dataField()!.dataType === 'email'">
      <span>{{ value() }}</span></ng-container
    >
    <ng-container *ngIf="dataField()!.dataType === 'notes'">
      <span>{{ value() }}</span></ng-container
    >
  </ng-container>

  <!-- *************** FORM FIELD ******************** -->
  <ng-container *ngIf="context === 'form' && hasFormControl">
    <!-- *************** STRING ******************** -->

    <mat-form-field
      *ngIf="dataField()!.dataType === 'string'"
      [formGroup]="parentForm!"
    >
      <mat-label
        *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
        >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
      >
      <mat-label
        *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
        >{{ dataField()!.label }}</mat-label
      >
      <input
        matInput
        type="text"
        [formControlName]="dataFieldId()"
        aria-autocomplete="none"
      />
      <mat-icon matSuffix></mat-icon>
    </mat-form-field>

    <!-- *************** EMAIL ******************** -->

    <mat-form-field
      *ngIf="dataField()!.dataType === 'email'"
      [formGroup]="parentForm!"
    >
      <mat-label
        *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
        >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
      >
      <mat-label
        *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
        >{{ dataField()!.label }}</mat-label
      >
      <input
        matInput
        type="text"
        [formControlName]="dataFieldId()"
        aria-autocomplete="none"
      />
      <mat-icon matSuffix></mat-icon>
    </mat-form-field>

    <!-- *************** PHONE ******************** -->

    <mat-form-field
      *ngIf="dataField()!.dataType === 'phone'"
      [formGroup]="parentForm!"
    >
      <mat-label
        *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
        >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
      >
      <mat-label
        *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
        >{{ dataField()!.label }}</mat-label
      >
      <custom-phone-input
        matInput
        [formControlName]="dataFieldId()"
        aria-autocomplete="none"
      />
      <mat-icon matSuffix>phone</mat-icon>
      <!-- <mat-hint>Country code is optional</mat-hint> -->
    </mat-form-field>

    <!-- *************** DATE ******************** -->

    <mat-form-field
      *ngIf="dataField()!.dataType === 'date'"
      [formGroup]="parentForm!"
    >
      <mat-label
        *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
        >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
      >
      <mat-label
        *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
        >{{ dataField()!.label }}</mat-label
      >
      <custom-date-input
        matInput
        [formControlName]="dataFieldId()"
        aria-autocomplete="none"
      />
      <mat-icon matSuffix>date</mat-icon>
      <!-- <mat-hint>Country code is optional</mat-hint> -->
    </mat-form-field>

    <!-- *************** NOTES ******************** -->

    <mat-form-field
      *ngIf="dataField()!.dataType === 'notes'"
      [formGroup]="parentForm!"
    >
      <mat-label
        *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
        >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
      >
      <mat-label
        *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
        >{{ dataField()!.label }}</mat-label
      >
      <custom-notes-input
        matInput
        [formControlName]="dataFieldId()"
        aria-autocomplete="none"
      />
      <mat-icon matSuffix>edit</mat-icon>
      <!-- <mat-hint>Country code is optional</mat-hint> -->
    </mat-form-field>

    <!-- *************** HOURS ******************** -->

    <mat-form-field
      *ngIf="dataField()!.dataType === 'hours'"
      [formGroup]="parentForm!"
    >
      <mat-label
        *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
        >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
      >
      <mat-label
        *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
        >{{ dataField()!.label }}</mat-label
      >
      <input
        matInput
        [formControlName]="dataFieldId()"
        aria-autocomplete="none"
      />
      <mat-icon matSuffix>time</mat-icon>
      <!-- <mat-hint>Country code is optional</mat-hint> -->
    </mat-form-field>

    <!-- *************** USER ******************** -->
    <ng-container *ngIf="dataField()!.dataType === 'user'">
      <mat-form-field *ngIf="userList(); else noList" [formGroup]="parentForm!">
        <mat-label
          *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
          >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
        >
        <mat-label
          *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
          >{{ dataField()!.label }}</mat-label
        >
        <mat-select [formControlName]="dataFieldId()">
          @for (selection of userList(); track selection) {
            <mat-option [value]="selection.id">{{
              selection.alias
            }}</mat-option>
          }
        </mat-select>
        <mat-icon matSuffix></mat-icon>
      </mat-form-field>

      <ng-template #noList>
        <mat-form-field>
          <mat-label
            *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
            >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
          >
          <mat-label
            *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
            >{{ dataField()!.label }}</mat-label
          >
          <!-- <mat-label>{{ "DATAFIELD.no_list" | translate }}</mat-label> -->
          <input matInput disabled [value]="'no list'" />
          <mat-icon matSuffix></mat-icon>
        </mat-form-field>
      </ng-template>
    </ng-container>

    <!-- *************** SELECT LIST ******************** -->
    <ng-container *ngIf="dataField()!.dataType === 'select_list'">
      <mat-form-field
        *ngIf="selectList(); else noList"
        [formGroup]="parentForm!"
      >
        <mat-label
          *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
          >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
        >
        <mat-label
          *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
          >{{ dataField()!.label }}</mat-label
        >
        <mat-select [formControlName]="dataFieldId()">
          @for (selection of selectList(); track selection) {
            <mat-option [value]="selection[0].entityId">{{
              _createLabelForList(selection)
            }}</mat-option>
          }
        </mat-select>
        <mat-icon matSuffix></mat-icon>
      </mat-form-field>

      <ng-template #noList>
        <mat-form-field>
          <mat-label
            *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
            >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
          >
          <mat-label
            *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
            >{{ dataField()!.label }}</mat-label
          >
          <!-- <mat-label>{{ "DATAFIELD.no_list" | translate }}</mat-label> -->
          <input matInput disabled [value]="'no list'" />
          <mat-icon matSuffix></mat-icon>
        </mat-form-field>
      </ng-template>
    </ng-container>
    <!-- *************************************  data_type **********************************-->
    <ng-container *ngIf="dataField()!.dataType === 'data_type'">
      <mat-form-field
        *ngIf="dataTypes(); else noDataTypes"
        [formGroup]="parentForm!"
      >
        <mat-label
          *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
          >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
        >
        <mat-label
          *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
          >{{ dataField()!.label }}</mat-label
        >
        <mat-select [formControlName]="dataFieldId()">
          @for (selection of dataTypes(); track selection) {
            <mat-option [value]="selection.name">{{
              "SYSTEM_LABELS." + selection.name | translate
            }}</mat-option>
          }
        </mat-select>
        <mat-icon matSuffix></mat-icon>
      </mat-form-field>

      <ng-template #noDataTypes>
        <mat-form-field>
          <mat-label>{{
            "SYSTEM_LABELS." + "no_data_types" | translate
          }}</mat-label>

          >
          <!-- <mat-label>{{ "DATAFIELD.no_list" | translate }}</mat-label> -->
          <input matInput disabled [value]="'no list'" />
          <mat-icon matSuffix></mat-icon>
        </mat-form-field>
      </ng-template>
    </ng-container>
    <!-- *************** LIST SELECT LIST ******************** -->
    <ng-container *ngIf="dataField()!.dataType === 'list_select_list'">
      <mat-form-field
        *ngIf="selectList(); else noSelectList"
        [formGroup]="parentForm!"
      >
        <mat-label
          *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
          >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
        >
        <mat-label
          *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
          >{{ dataField()!.label }}</mat-label
        >
        <mat-select [formControlName]="dataFieldId()" multiple>
          @for (selection of selectList(); track selection) {
            <mat-option [value]="selection[0].entityId">{{
              _createLabelForList(selection)
            }}</mat-option>
          }
        </mat-select>
        <mat-icon matSuffix></mat-icon>
      </mat-form-field>

      <ng-template #noSelectList>
        <mat-form-field>
          <mat-label
            *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
            >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
          >
          <mat-label
            *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
            >{{ dataField()!.label }}</mat-label
          >
          <!-- <mat-label>{{ "DATAFIELD.no_list" | translate }}</mat-label> -->
          <input matInput disabled [value]="'no list'" />
          <mat-icon matSuffix></mat-icon>
        </mat-form-field>
      </ng-template>
    </ng-container>

    <!-- *************** USER LIST ******************** -->
    <ng-container *ngIf="dataField()!.dataType === 'user_list'">
      <mat-form-field
        *ngIf="userList(); else noSelectList"
        [formGroup]="parentForm!"
      >
        <mat-label
          *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
          >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
        >
        <mat-label
          *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
          >{{ dataField()!.label }}</mat-label
        >
        <mat-select [formControlName]="dataFieldId()" multiple>
          @for (selection of userList(); track selection) {
            <mat-option [value]="selection.id">{{
              selection.alias
            }}</mat-option>
          }
        </mat-select>
        <mat-icon matSuffix></mat-icon>
      </mat-form-field>

      <ng-template #noSelectList>
        <mat-form-field>
          <mat-label
            *ngIf="dataField()?.clientId == SYSTEM_IDENTITIES.system_client_id"
            >{{ "SYSTEM_LABELS." + dataField()!.name | translate }}</mat-label
          >
          <mat-label
            *ngIf="dataField()?.clientId != SYSTEM_IDENTITIES.system_client_id"
            >{{ dataField()!.label }}</mat-label
          >
          <!-- <mat-label>{{ "DATAFIELD.no_list" | translate }}</mat-label> -->
          <input matInput disabled [value]="'no list'" />
          <mat-icon matSuffix></mat-icon>
        </mat-form-field>
      </ng-template>
    </ng-container>

    <!--

    <mat-form-field
      *ngIf="fieldType() && fieldType()?.dataType === 'date'"
      appearance="fill"
    >
      <mat-label>{{ "Choose a date" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        autocomplete="off"
        [formControlName]="control"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <ng-container *ngIf="fieldType() && fieldType()?.dataType === 'boolean'">
      <mat-label>
        @if (safeDataField) {
          {{ safeDataField.label }}
        } @else {
          {{ "SYSTEM_LABELS." + dataField()?.name | translate }}
        }
      </mat-label>
      <mat-slide-toggle
        [formControlName]="control"
        color="primary"
      ></mat-slide-toggle>
    </ng-container>-->
  </ng-container>
</ng-container>
