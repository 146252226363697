export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyCxDHUzBnzaWvPHIDtpiX8AEPaqAzrIcSg',
    authDomain: 'dataportal-authentication.firebaseapp.com',
    projectId: 'dataportal-authentication',
    storageBucket: 'dataportal-authentication.appspot.com',
    messagingSenderId: '108671710754',
    appId: '1:108671710754:web:67f9c11001fdead9d0bc7b',
  },

  backendUrl:
    'https://api.phenoxdataportal.com/api/ValidateToken?code=3S5SJvks7SJTsw7Ie5QRUyoRr4I-IXhKNIjLKA_hDcECAzFuBnN7lw==',
  firebaseRedirectUri: 'https://www.phenoxdataportal.com/',
};
