export const SYSTEM_IDENTITIES = {
  system_client_id: 1,

  // *************** Table columns
  table_columns_data_fields: 1,
  table_columns_contacts: 3,
  table_columns_clients: 28,
  table_columns_projects: 32,

  // form_data_fields: 2,

  // *************** Data Fields
  data_field_name: 1,
  data_field_description: 2,
  data_field_datatype: 3,
  data_field_label: 4,
  // row_in_form: 6,
  // row_position_in_form: 7,

  // *************** Entity Types

  entityType_selectList: 'select_list',
  entityType_contact: 'contact',
  entityType_client: 'client',
  entityType_project: 'project',
  entityType_table_columns: 'table_columns',
  // entity_name: 53,
  // entity_type: 54,
  // entity_description: 55,
  // entity_id: 56,
};

export interface sqlEntityType {
  id: number;
  entityType: string;
}
export interface sqlUserListItem {
  id: number;
  alias: string;
}

export interface sqlDataType {
  id: number;
  name: string;
  description: string;
  metaData: string;
}

export interface sqlDataFieldId {
  dataFieldId: number;
}

export interface sqlEntityId {
  entityId: number;
}

export type sqlEntity = data[];

export interface data {
  id: number;
  entityId: number;
  entityType: string;
  data_value: string;
  dataFieldId: number;
  clientId: number;
}

export type sqlTimeReg = timeData[];

export interface timeData {
  timeId: number;
  userId: number;
  clientId: number;
  createdDate: string;
  createdBy: number;
  editedDate: string;
  editedBy: number;
  regStatus: string;
  inActiveList: boolean;
  id: number;
  data_value: string;
  dataFieldId: number;
}

export interface sqlRefreshRates {
  entityType: string;
  refreshRate: number;
}

export interface sqlUser {
  Id: number;
  Alias: string;
  Theme: 'dark' | 'light';
  Language: string;
}

export interface sqlDataField {
  id: number;
  name: string;
  label: string;
  description: string;
  dataType: string;
  clientId: number;
  metaData: string | null;
}

export interface sqlFieldType {
  id: string;
  dataType: string;
  description: string;
  label: string;
  name: string;
}
