<mat-card class="auth-card-container">
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>

  <form (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline">
      <input
        matInput
        type="email"
        placeholder="Email"
        #emailInput
        autocomplete="off"
        [formControl]="emailFormControl"
      />

      @if (emailFormControl.hasError('email') &&
      !emailFormControl.hasError('required')) {
      <mat-error>Please enter a valid email address</mat-error>
      } @if (emailFormControl.hasError('required')) {
      <mat-error>Email is <strong>required</strong></mat-error>
      }
    </mat-form-field>

    <mat-checkbox [labelPosition]="labelPosition" [disabled]="disabled">
      Remember me
    </mat-checkbox>

    <mat-card-actions align="end">
      <button
        mat-button
        [disabled]="emailInput.value === ''"
        type="button"
        (click)="emailInput.value = ''; emailFormControl.setErrors(null)"
      >
        CLEAR
      </button>
      <button mat-raised-button color="next" type="button" (click)="onSubmit()">
        NEXT
      </button>
    </mat-card-actions>
  </form>
</mat-card>
