<mat-card class="auth-card-container">
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>
  @if(loggedinstatus()){
  <mat-icon color="success">thumb_up</mat-icon>} @else{
  <mat-icon color="failure">thumb_down</mat-icon>}
  <mat-card-content>
    <p>you are being redirected {{ dots }}</p>
  </mat-card-content>
</mat-card>
