import { Component, inject, computed, signal, Inject } from '@angular/core';

import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  MatDialogModule,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { InputFormComponent } from '../../../ui-components/input-form/input-form.component';

import { backendTypes } from '../../../../assets/types';
import { editDataFieldForm } from '../../../../assets/model/data.model';
import { typeGuards } from '../../../../services/functions/typeguards/typeguards';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DataService } from '../../../../services/dataservices/data.service';
import { atLeastOne } from '../../../../assets/shared/validators';

const SYSTEM_IDENTITIES = backendTypes.SYSTEM_IDENTITIES;

const nonEmptyValidator = Validators.required;

@Component({
  selector: 'app-editcontact',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    InputFormComponent,
    FormsModule,
    ReactiveFormsModule,

    TranslateModule,
  ],
  templateUrl: './editdatafield.component.html',
  styleUrl: './editdatafield.component.scss',
})
export class EditdatafieldComponent {
  form = new FormGroup({}, { validators: atLeastOne(nonEmptyValidator) });
  formFields = signal(editDataFieldForm);

  dataService = inject(DataService);
  dialog = inject(MatDialogRef);
  translate = inject(TranslateService);

  constructor(
    public dialogRef: MatDialogRef<EditdatafieldComponent>,
    @Inject(MAT_DIALOG_DATA) public data: backendTypes.sqlEntity,
  ) {
    console.log('data in edit contact: ' + JSON.stringify(this.data));
  }
  onSubmit() {
    console.log(this.form.getRawValue());
    let input: Record<string, string> = this.form.getRawValue();

    if (
      input &&
      input[SYSTEM_IDENTITIES.data_field_name.toString()] ==
        this.data.find(
          (f) => f.dataFieldId === SYSTEM_IDENTITIES.data_field_name,
        )?.data_value &&
      input[SYSTEM_IDENTITIES.data_field_description.toString()] ==
        this.data.find(
          (f) => f.dataFieldId === SYSTEM_IDENTITIES.data_field_description,
        )?.data_value &&
      input[SYSTEM_IDENTITIES.data_field_label.toString()] ==
        this.data.find(
          (f) => f.dataFieldId === SYSTEM_IDENTITIES.data_field_label,
        )?.data_value
    ) {
      return;
    }

    let convertedInput = {
      id: this.data[0].id,
      name: input[SYSTEM_IDENTITIES.data_field_name.toString()],
      description: input[SYSTEM_IDENTITIES.data_field_description.toString()],
      label: input[SYSTEM_IDENTITIES.data_field_label.toString()],
    };

    if (typeGuards.isHttpUpdateDataFieldInput(convertedInput)) {
      this.dataService.dbUpdateDataField(convertedInput).subscribe({
        next: (response) => {
          if (response) {
            this.dialog.close(true);
          } else {
            console.error('Update dataField failed');
          }
        },
        error: (err) => {
          console.error('Save failed:', err);
        },
      });
    }

    console.log('no valid update');
    return true;
  }
}
