import { Injectable, inject, Injector } from '@angular/core';
import {
  Auth,
  User,
  authState,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
} from '@angular/fire/auth';
import { Observable, shareReplay, filter, take, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { ConfigService } from '../settings and config/config.service';
import { SettingsService } from '../settings and config/settings.service';
import { HeadersService } from '../httpservices/headers.service';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user$: Observable<User | null>;
  private injector = inject(Injector);

  constructor(
    private firebaseAuth: Auth,
    private router: Router,
  ) {
    this.user$ = authState(this.firebaseAuth).pipe(shareReplay(1));
    // Initialize user$ with Firebase authState observable to track auth state changes
    this.user$
      .pipe(
        filter((user) => !!user), // Filter out null values
        take(1), // Only take the first valid user
      )
      .subscribe((user) => {
        // User is authenticated, navigate to the "waiting" page
        console.log('User authenticated:', user);
        this.router.navigate(['/waiting-for-initialization']);
        // Proceed with initialization
        this.startInitializationSequence();
      });
  }

  // Login method to send sign-in link to email
  login(email: string): Promise<void> {
    return sendSignInLinkToEmail(this.firebaseAuth, email, {
      url: environment.firebaseRedirectUri,
      handleCodeInApp: true,
    })
      .then(() => {
        window.localStorage.setItem('emailForSignIn', email);
        this.router.navigate(['checkmail']);
      })
      .catch((error) => {
        console.error('Error sending email sign-in link:', error);
      });
  }

  // Complete login after user clicks the sign-in link
  completeLogin(href: string): Promise<void> {
    if (isSignInWithEmailLink(this.firebaseAuth, href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }

      return signInWithEmailLink(this.firebaseAuth, email || '', href)
        .then(() => {
          window.localStorage.removeItem('emailForSignIn');

          this.startInitializationSequence();
        })
        .catch((error) => {
          console.error('Error completing login:', error);
          this.router.navigate(['/login']);
        });
    } else {
      this.router.navigate(['/login']);
      return Promise.reject('No email link detected');
    }
  }

  // Log out method
  logOut(): void {
    signOut(this.firebaseAuth)
      .then(() => {
        this.router.navigate(['/login']);
      })
      .catch((error) => {
        console.error('Error during logout:', error);
      });
  }

  // Initialization sequence after login

  private async startInitializationSequence(): Promise<void> {
    try {
      console.log('Starting initialization sequence...');
      const headersService = this.injector.get(HeadersService);
      const configService = this.injector.get(ConfigService);
      const settingsService = this.injector.get(SettingsService);
      const userService = this.injector.get(UserService);

      const user = await firstValueFrom(this.user$);
      console.log('User in initialization sequence:', user);
      // Step 1: Get configuration from the backend
      await configService.updateConfig();
      console.log('Configuration loaded');

      // Step 2: Get settings after configuration
      await settingsService.updateSettings();
      console.log('Settings loaded');

      // Step 3: Get user info after settings
      await userService.getUser();
      console.log('User info loaded');

      // After successful initialization, navigate to the dashboard
      this.router.navigate(['/dashboard']);
    } catch (error) {
      console.error('Error during initialization sequence:', error);
      // Navigate to error page if initialization fails
      this.router.navigate(['/initialization-error']);
    }
  }
}
