import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap, catchError, from, of, map, Observable } from 'rxjs';

import { httpTypes } from '../../assets/types';
import { HeadersService } from './headers.service';
import { environment } from '../../../src/environments/environment';
import { ErrorHandlingService } from '../errorhandling/error-handling.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private http = inject(HttpClient);
  private headers = inject(HeadersService);
  private backendUrl = environment.backendUrl;
  private errorHandlingService = inject(ErrorHandlingService);

  fetchData(request: httpTypes.DbRequest) {
    const headersObservable = from(this.headers.createHeaders());
    return headersObservable.pipe(
      switchMap((headers) => {
        return this.http.post<httpTypes.DbResponse>(this.backendUrl, request, {
          headers: headers,
        });
      }),
      catchError((error) => {
        this.errorHandlingService.handleError(error.code, error.message);
        return of(null);
      }),
    );
  }
}
