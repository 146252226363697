<div
  role="group"
  class="custom-date-input-container"
  [formGroup]="parts"
  [attr.aria-labelledby]="_formField ? _formField.getLabelId() : null"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut($event)"
  (click)="onContainerClick()"
>
  <!-- First Position -->
  <ng-container *ngIf="order.charAt(0) === 'Y'">
    <input
      class="custom-date-input-element"
      formControlName="year"
      size="4"
      maxLength="4"
      aria-label="Year"
      (input)="_handleInput(parts.controls.year, getElementInPosition(1))"
      #year
      [placeholder]="shouldLabelFloat ? 'YYYY' : ''"
      aria-autocomplete="none"
    />
    <span class="custom-date-input-spacer">{{ separator }}</span>
  </ng-container>
  <ng-container *ngIf="order.charAt(0) === 'M'">
    <input
      class="custom-date-input-element"
      formControlName="month"
      maxLength="2"
      size="2"
      aria-label="Month"
      (input)="_handleInput(parts.controls.month, getElementInPosition(1))"
      #month
      [placeholder]="shouldLabelFloat ? 'MM' : ''"
      aria-autocomplete="none"
    />
    <span class="custom-date-input-spacer">{{ separator }}</span>
  </ng-container>
  <ng-container *ngIf="order.charAt(0) === 'D'">
    <input
      class="custom-date-input-element"
      formControlName="day"
      maxLength="2"
      size="2"
      aria-label="Day"
      (input)="_handleInput(parts.controls.day, getElementInPosition(1))"
      #day
      [placeholder]="shouldLabelFloat ? 'DD' : ''"
      aria-autocomplete="none"
    />
    <span class="custom-date-input-spacer">{{ separator }}</span>
  </ng-container>

  <!-- Second Position -->
  <ng-container *ngIf="order.charAt(1) === 'Y'">
    <input
      class="custom-date-input-element"
      formControlName="year"
      size="4"
      maxLength="4"
      aria-label="Year"
      (input)="_handleInput(parts.controls.year, getElementInPosition(2))"
      (keyup.backspace)="
        autoFocusPrev(parts.controls.year, getElementInPosition(0))
      "
      #year
      [placeholder]="shouldLabelFloat ? 'YYYY' : ''"
      aria-autocomplete="none"
    />
    <span class="custom-date-input-spacer">{{ separator }}</span>
  </ng-container>
  <ng-container *ngIf="order.charAt(1) === 'M'">
    <input
      class="custom-date-input-element"
      formControlName="month"
      maxLength="2"
      size="2"
      aria-label="Month"
      (input)="_handleInput(parts.controls.month, getElementInPosition(2))"
      (keyup.backspace)="
        autoFocusPrev(parts.controls.month, getElementInPosition(0))
      "
      #month
      [placeholder]="shouldLabelFloat ? 'MM' : ''"
      aria-autocomplete="none"
    />
    <span class="custom-date-input-spacer">{{ separator }}</span>
  </ng-container>
  <ng-container *ngIf="order.charAt(1) === 'D'">
    <input
      class="custom-date-input-element"
      formControlName="day"
      maxLength="2"
      size="2"
      aria-label="Day"
      (input)="_handleInput(parts.controls.day, getElementInPosition(2))"
      (keyup.backspace)="
        autoFocusPrev(parts.controls.day, getElementInPosition(0))
      "
      #day
      [placeholder]="shouldLabelFloat ? 'DD' : ''"
      aria-autocomplete="none"
    />
    <span class="custom-date-input-spacer">{{ separator }}</span>
  </ng-container>

  <!-- Third Position -->
  <ng-container *ngIf="order.charAt(2) === 'Y'">
    <input
      class="custom-date-input-element"
      formControlName="year"
      size="4"
      maxLength="4"
      aria-label="Year"
      (input)="_handleInput(parts.controls.year)"
      (keyup.backspace)="
        autoFocusPrev(parts.controls.year, getElementInPosition(1))
      "
      #year
      [placeholder]="shouldLabelFloat ? 'YYYY' : ''"
      aria-autocomplete="none"
    />
  </ng-container>
  <ng-container *ngIf="order.charAt(2) === 'M'">
    <input
      class="custom-date-input-element"
      formControlName="month"
      maxLength="2"
      size="2"
      aria-label="Month"
      (input)="_handleInput(parts.controls.month)"
      (keyup.backspace)="
        autoFocusPrev(parts.controls.month, getElementInPosition(1))
      "
      #month
      [placeholder]="shouldLabelFloat ? 'MM' : ''"
      aria-autocomplete="none"
    />
  </ng-container>
  <ng-container *ngIf="order.charAt(2) === 'D'">
    <input
      class="custom-date-input-element"
      formControlName="day"
      maxLength="2"
      size="2"
      aria-label="Day"
      (input)="_handleInput(parts.controls.day)"
      (keyup.backspace)="
        autoFocusPrev(parts.controls.day, getElementInPosition(1))
      "
      #day
      [placeholder]="shouldLabelFloat ? 'DD' : ''"
      aria-autocomplete="none"
    />
  </ng-container>
</div>
