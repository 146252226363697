<BR />

<div class="active-timereg-wrapper">
  <div class="active-timereg-functions-wrapper">
    <div>
      <button class="menu-button" mat-raised-button>Remove Rows</button>
      <button class="menu-button" mat-raised-button (click)="addRow()">
        Add Registration
      </button>
    </div>
    <button class="menu-button" mat-raised-button>Delete Registration</button>
  </div>

  <span>
    <mat-checkbox
      (change)="selectAll($event)"
      [checked]="isAllSelected()"
      [indeterminate]="!isAllSelected() && isAnySelected()"
      [disabled]="!activeTimeRegs || activeTimeRegs()?.length == 0"
    ></mat-checkbox>
  </span>
  <div class="active-timereg-items-wrapper">
    <ng-container *ngIf="activeTimeRegs(); else noActiveTimeReg">
      <ng-container *ngFor="let T of activeTimeRegs(); track: T">
        <mat-checkbox
          (change)="singleSelect($event, 1)"
          [checked]="selectedIDs.includes(1)"
        ></mat-checkbox>
        <ng-container *ngFor="let col of columns; track: col">
          <app-data-field
            [fieldId]="col.dataFieldId.toString()"
            [data]="T"
            [template]="'table-cell'"
          ></app-data-field>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #noActiveTimeReg>
      <span>No active registrations</span>
    </ng-template>
  </div>
</div>
