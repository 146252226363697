import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ErrorHandlingService } from '../errorhandling/error-handling.service';
import { UserService } from '../userservices/user.service';

export const isAuthorizedGuard = (role: string[]): CanActivateFn => {
  return () => {
    const userService = inject(UserService);
    const router = inject(Router);
    const errorHandlingService = inject(ErrorHandlingService);

    const isAuthorized = true;

    if (!isAuthorized) {
      errorHandlingService.handleError(401, 'Unauthorized');
    }
    return isAuthorized;
  };
};
