<div class="multitabs-container">
  <BR />
  <div>
    @for (link of adminButtonLinks; track link) {
      <button
        mat-list-item
        mat-raised-button
        class="{{
          link.url === currentUrl()
            ? 'menu-button buttonSelected'
            : 'menu-button'
        }}"
        [routerLink]="[link.url]"
      >
        {{ "ADMIN." + link.label | translate }}
      </button>
    }
  </div>

  <div class="content-area">
    <router-outlet></router-outlet>
  </div>
</div>
