<mat-toolbar>
  <button mat-icon-button [routerLink]="['/dashboard']">
    <mat-icon>home</mat-icon>
  </button>
  <span class="logo">phenox </span>
  <span class="title">Time Registration</span>
  <div>
    @for (link of menuButtonLinks; track link) {
      <button
        mat-raised-button
        class="{{
          link.url === currentUrl()
            ? 'menu-button buttonSelected'
            : 'menu-button'
        }}"
        [routerLink]="[link.url]"
      >
        {{ "DASHBOARD." + link.label | translate }}
      </button>
    }
  </div>
  <span class="toolbar-spacer"></span>
  <app-langselector class="icons"></app-langselector>
  <div class="icons">
    <mat-radio-group
      aria-label="Select theme"
      (change)="changeTheme($event.value)"
    >
      <mat-radio-button [checked]="themeSelected() === 'light'" value="light"
        >Light</mat-radio-button
      >
      <mat-radio-button [checked]="themeSelected() === 'dark'" value="dark"
        >Dark</mat-radio-button
      >
    </mat-radio-group>
  </div>
  <button mat-icon-button class="icons" (click)="authService.logOut()">
    <mat-icon>exit_to_app</mat-icon>
  </button>
</mat-toolbar>

<div class="outlet-content-wrapper">
  <router-outlet></router-outlet>
</div>
