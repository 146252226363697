<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="headerContainer">
    <h2 mat-dialog-title>{{ "DATAFIELDS." + data.formTitle | translate }}</h2>
  </div>
  <mat-dialog-content>
    <app-input-form
      [inputForm]="data.form"
      [inputData]="data.entity"
      [inputParentForm]="form"
    ></app-input-form>

    <div class="errorcontainer">
      @if (form.invalid) {
        <span>fields marked with * are required</span>
      } @else {
        <span class="spacer"> </span>
      }
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      [mat-dialog-close]="false"
      (click)="form.removeControl('dataForm')"
    >
      Cancel
    </button>
    <button mat-button type="submit" [disabled]="!form.valid">Save</button>
  </mat-dialog-actions>
</form>
