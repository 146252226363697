<ng-container *ngIf="formGroupInitialized && parentForm; else noForm">
  <form class="input-form" [formGroup]="parentForm" autoComplete="off">
    <div
      class="grid-wrapper"
      [style.gridTemplateColumns]="'repeat(' + maxOrder + ', 1fr)'"
    >
      <ng-container *ngFor="let row of gridData">
        <ng-container *ngFor="let field of row">
          <div *ngIf="field" class="grid-item">
            <app-data-field
              [fieldId]="field.dataFieldId.toString()"
              [data]="data()"
              [template]="'form'"
              [parentForm]="parentForm"
            ></app-data-field>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </form>
</ng-container>

<ng-template #noForm>
  <h3>{{ "INPUTFORM." + initMessage | translate }}</h3>
</ng-template>
