import {
  AbstractControl,
  ValidatorFn,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';

import { Subscription } from 'rxjs';

export function atLeastOne(
  validator: ValidatorFn,
  controls: string[] | null = null,
): ValidatorFn {
  let subscriptions: Subscription[] = [];

  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup;

    if (!formGroup.controls) {
      return null;
    }

    // Unsubscribe from any previous subscriptions to avoid memory leaks
    subscriptions.forEach((sub) => sub.unsubscribe());
    subscriptions = [];

    const controlsToValidate = controls
      ? controls.map((name) => formGroup.get(name)).filter(Boolean)
      : Object.values(formGroup.controls);

    // Subscribe to each control's valueChanges
    controlsToValidate.forEach((ctrl) => {
      const sub = ctrl!.valueChanges.subscribe(() => {
        control.updateValueAndValidity({ onlySelf: true });
      });
      subscriptions.push(sub);
    });

    const hasAtLeastOne = controlsToValidate.some((ctrl) => !validator(ctrl!));
    return hasAtLeastOne ? null : { atLeastOne: true };
  };
}
