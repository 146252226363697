import { Injectable, inject, signal, computed, Signal } from '@angular/core';
import { switchMap, catchError, from, tap, map, Observable } from 'rxjs';

// import { ConfigService } from '../settings and config/config.service';
import { HttpService } from '../httpservices/http.service';
import { backendTypes, frontendTypes, httpTypes } from '../../assets/types';

import { SettingsService } from '../settings and config/settings.service';
import { typeGuards } from '../functions/typeguards/typeguards';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  // private config = inject(ConfigService);
  private settings = inject(SettingsService);
  private http = inject(HttpService);

  private _entities = signal<backendTypes.sqlEntity[] | null>(null);

  groupEntities(entities: backendTypes.sqlEntity): frontendTypes.Data {
    const groupedEntities = Object.values(
      entities.reduce(
        (acc, item) => {
          // If there's no array for this entityId yet, create one
          if (!acc[item.entityId]) {
            acc[item.entityId] = [];
          }
          // Add the item to the respective entityId group
          acc[item.entityId].push(item);
          return acc;
        },
        {} as { [key: number]: backendTypes.data[] },
      ), // Using a dictionary to accumulate grouped items
    );

    return groupedEntities;
  }

  constructor() {
    // **************** get all entities ****************
    console.log('dataservice started');
    this.dbGetEntityByType('*').subscribe((entities) => {
      if (entities && typeGuards.isSqlEntity(entities)) {
        const groupedData = this.groupEntities(entities);

        this._entities.set(groupedData);
        console.log('_entities ser');
      }
    });
  }

  // ************************************ entity filter function  ***************************

  getEntitiesByFilter(
    globalFilters: frontendTypes.EntityFilterCondition[],
    fieldSpecificFilters?: frontendTypes.EntityDataFilterCondition[],
    combineGlobalWith?: 'AND' | 'OR',
    combineFieldWith?: 'AND' | 'OR',
  ): Signal<frontendTypes.Data | null> {
    console.log('getEntitiesByFilter: filter ', globalFilters);
    return computed(() => {
      const entities = this._entities();

      if (!entities) return null;

      const filteredEntities = entities.filter((entity) => {
        // Apply global filters
        const globalMatch =
          globalFilters.length === 0 ||
          globalFilters[combineGlobalWith === 'AND' ? 'every' : 'some'](
            (filter) => {
              const { key, value, operator = 'equals' } = filter;
              const entityValue = entity[0][key];

              if (operator === 'equals') {
                return entityValue === value;
              } else if (operator === 'contains') {
                return (
                  typeof entityValue === 'string' && entityValue.includes(value)
                );
              }
              return false;
            },
          );

        if (!globalMatch) return false;

        // Apply field-specific filters if provided
        if (fieldSpecificFilters && fieldSpecificFilters.length > 0) {
          const fieldMatch = fieldSpecificFilters[
            combineFieldWith === 'AND' ? 'every' : 'some'
          ]((filter) => {
            return entity.some((obj) => {
              const { dataFieldId, data_value, operator = 'equals' } = filter;

              if (obj.dataFieldId === dataFieldId) {
                if (operator === 'equals') {
                  return obj.data_value === data_value;
                } else if (operator === 'contains') {
                  return (
                    typeof obj.data_value === 'string' &&
                    obj.data_value.includes(data_value)
                  );
                }
              }
              return false;
            });
          });

          if (!fieldMatch) return false;
        }

        // Entity passes all filters
        return true;
      });

      // Return filtered results, or null if no matches are found
      return filteredEntities.length > 0 ? filteredEntities : null;
    });
  }

  dbDeleteEntity(entity: number): Observable<any> {
    return this.http
      .fetchData({
        request: 'delete_entity',
        requestType: 'write',
        parameters: { entityId: entity },
      })
      .pipe(
        map((response) => {
          if (response && typeGuards.isSqlEntityId(response[0])) {
            const id = response[0].entityId;
            if (this._entities()) {
              this._entities.set(
                this._entities()!.filter(
                  (innerArray) => innerArray[0]?.entityId !== id,
                ),
              );
            }
            return response;
          }
          return null;
        }),
      );
  }

  dbInsertEntity(entity: httpTypes.InsertEntityInput): Observable<any> {
    return this.http
      .fetchData({
        request: 'insert_entity',
        requestType: 'write',
        parameters: entity,
      })
      .pipe(
        map((response) => {
          if (response && typeGuards.isSqlEntity(response)) {
            if (!this._entities()) {
              this._entities.set([response]);
            } else {
              this._entities.update((entities) => [...entities!, response]);
            }
            return response;
          }
          return null;
        }),
      );
  }

  dbUpdateEntity(entity: httpTypes.UpdateEntityInput): Observable<any> {
    return this.http
      .fetchData({
        request: 'update_entity',
        requestType: 'write',
        parameters: entity,
      })
      .pipe(
        map((response) => {
          if (response && typeGuards.isSqlEntity(response)) {
            if (!this._entities()) {
              this._entities.set([response]);
            } else {
              const exists = this._entities()?.some(
                (f) => f[0].id === response[0].id,
              );

              if (exists) {
                // Update the existing dataField if it exists
                this._entities.update((entities) =>
                  entities!.map((f) =>
                    f[0].id === response[0].id ? response : f,
                  ),
                );
              } else {
                // Add the new dataField if it doesn't exist
                this._entities.update((entities) => [...entities!, response]);
              }
            }
            return response;
          }
          return null;
        }),
      );
  }

  dbInsertDataField(dataField: backendTypes.sqlDataField): Observable<any> {
    return this.http
      .fetchData({
        request: 'insert_datafield',
        requestType: 'write',
        parameters: {
          name: dataField.name,
          label: dataField.label,
          description: dataField.description,
          dataType: dataField.dataType,
        },
      })
      .pipe(
        map((response) => {
          if (response && typeGuards.isSqlDataField(response[0])) {
            this.settings.setDataField(response[0]);
            console.log('dataFields: ', this.settings.dataFields());
            return response;
          }
          return null;
        }),
      );
  }

  dbUpdateDataField(
    dataField: httpTypes.UpdateDataFieldInput,
  ): Observable<any> {
    return this.http
      .fetchData({
        request: 'update_datafield',
        requestType: 'write',
        parameters: {
          id: dataField.id,
          name: dataField.name,
          label: dataField.label,
          description: dataField.description,
        },
      })
      .pipe(
        map((response) => {
          if (response && typeGuards.isSqlDataField(response[0])) {
            this.settings.setDataField(response[0]);
            return response;
          }
          return null;
        }),
      );
  }

  dbDeleteDataField(id: number) {
    return this.http
      .fetchData({
        request: 'delete_datafield',
        requestType: 'write',
        parameters: {
          dataFieldId: id,
        },
      })
      .pipe(
        map((response) => {
          if (response && typeGuards.isSqlDataFieldId(response[0])) {
            this.settings.deleteDataField(response[0].dataFieldId);
            return response;
          }
          return null;
        }),
      );
  }

  dbGetEntityById(id: number): Observable<backendTypes.sqlEntity | null> {
    return this.http
      .fetchData({
        request: 'get_entity_by_id',
        requestType: 'read',
        parameters: { entityId: id },
      })
      .pipe(
        map((entity) => {
          if (typeGuards.isSqlEntity(entity)) {
            return entity;
          }
          return null;
        }),
      );
  }

  dbGetEntityByType(type: string): Observable<backendTypes.sqlEntity | null> {
    return this.http
      .fetchData({
        request: 'get_entity_by_type',
        requestType: 'read',
        parameters: { type: type },
      })
      .pipe(
        map((entity) => {
          if (typeGuards.isSqlEntity(entity)) {
            return entity;
          }
          return null;
        }),
      );
  }
}
