import { Injectable, inject, signal, computed, effect } from '@angular/core';
import { catchError, of } from 'rxjs';

import { typeGuards } from '../functions/typeguards/typeguards';
import { backendTypes, httpTypes } from '../../assets/types';
import { HttpService } from '../httpservices/http.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private http = inject(HttpService);

  constructor() {
    // Theme preference effect
    effect(() => {
      if (this.themePreference() === 'dark') {
        document.body.classList.add('dark-theme');
      } else {
        document.body.classList.remove('dark-theme');
      }
    });

    // Wait for login completion before fetching user data

    // effect(() => {
    //   const trigger = this.config.refreshUser();
    //   this.getUser();
    // });
  }

  private _user = signal<backendTypes.sqlUser | null>(null);

  user = computed(() => this._user());
  themePreference = computed(() => this._user()?.Theme || 'light');

  getUser(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .fetchData({
          request: 'get_user',
          requestType: 'read',
        })
        .subscribe({
          next: (user) => {
            if (user && typeGuards.isSqlUserArray(user)) {
              this._user.set(user[0] as backendTypes.sqlUser);
              resolve(); // Resolve the promise on success
            }
            reject('No valid user found'); // Reject if no valid user is found
          },
          error: (err) => {
            console.error('Error fetching user data', err);
            reject(err); // Reject the promise on error
          },
        });
    });
  }

  updateUser(updateFields: httpTypes.UpdateUserInput) {
    const request: httpTypes.DbRequest = {
      request: 'update_user',
      requestType: 'write',
      parameters: updateFields,
    };

    this.http.fetchData(request).subscribe((user) => {
      if (user && typeGuards.isSqlUserArray(user)) {
        this._user.set(user[0] as backendTypes.sqlUser);
      }
    });
  }
}
