import { FocusMonitor } from '@angular/cdk/a11y';
import { AsyncPipe, JsonPipe, CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  booleanAttribute,
  computed,
  effect,
  forwardRef,
  inject,
  input,
  model,
  signal,
  untracked,
  viewChild,
  Input,
  HostBinding,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormFieldControl,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';

class CustomDate {
  constructor(
    public day: string,
    public month: string,
    public year: string,
  ) {}
}

@Component({
  selector: 'custom-date-input',
  styleUrl: 'date.component.scss',
  templateUrl: 'date.component.html',
  host: {
    '[class.custom-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
  providers: [{ provide: MatFormFieldControl, useExisting: DateInput }],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateInput
  implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy
{
  separator = '-';
  order = 'DMY';
  static nextId = 0;
  readonly dayInput = viewChild.required<HTMLInputElement>('day');
  readonly monthInput = viewChild.required<HTMLInputElement>('month');
  readonly yearInput = viewChild.required<HTMLInputElement>('year');
  ngControl = inject(NgControl, { optional: true, self: true });
  readonly parts: FormGroup<{
    day: FormControl<string | null>;
    month: FormControl<string | null>;
    year: FormControl<string | null>;
  }>;
  readonly stateChanges = new Subject<void>();
  readonly touched = signal(false);
  readonly controlType = 'custom-date-input';
  readonly id = `custom-date-input-${DateInput.nextId++}`;
  readonly _userAriaDescribedBy = input<string>('', {
    alias: 'aria-describedby',
  });
  readonly _placeholder = input<string>('', { alias: 'placeholder' });
  readonly _required = input<boolean, unknown>(false, {
    alias: 'required',
    transform: booleanAttribute,
  });
  readonly _disabledByInput = input<boolean, unknown>(false, {
    alias: 'disabled',
    transform: booleanAttribute,
  });
  readonly _value = model<CustomDate | null>(null, { alias: 'value' });
  onChange = (_: any) => {};
  onTouched = () => {};

  protected readonly _formField = inject(MAT_FORM_FIELD, {
    optional: true,
  });

  private readonly _focused = signal(false);
  private readonly _disabledByCva = signal(false);
  private readonly _disabled = computed(
    () => this._disabledByInput() || this._disabledByCva(),
  );
  private readonly _focusMonitor = inject(FocusMonitor);
  private readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  get focused(): boolean {
    return this._focused();
  }

  get empty() {
    const {
      value: { day, month, year },
    } = this.parts;

    return !day && !month && !year;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get userAriaDescribedBy() {
    return this._userAriaDescribedBy();
  }

  get placeholder(): string {
    return this._placeholder();
  }

  get required(): boolean {
    return this._required();
  }

  get disabled(): boolean {
    return this._disabled();
  }

  get value(): string | null {
    if (
      !this._value() ||
      !this._value()?.day ||
      !this._value()?.month ||
      !this._value()?.year
    ) {
      return null;
    }
    const date = new Date(
      Number(this._value()!.year),
      Number(this._value()!.month) - 1,
      Number(this._value()!.day),
    ).toISOString();
    return date;
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched();
  }
  constructor() {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.parts = inject(FormBuilder).group({
      year: [
        '',
        [
          // Validators.minLength(4),
          Validators.maxLength(4),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      month: [
        '',
        [
          // Validators.minLength(2),
          Validators.maxLength(2),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      day: [
        '',
        [
          // Validators.minLength(2),
          Validators.maxLength(2),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
    });

    effect(() => {
      // Read signals to trigger effect.
      this._placeholder();
      this._required();
      this._disabled();
      this._focused();
      // Propagate state changes.
      untracked(() => this.stateChanges.next());
    });

    effect(() => {
      if (this._disabled()) {
        untracked(() => this.parts.disable());
      } else {
        untracked(() => this.parts.enable());
      }
    });

    effect(() => {
      const value = this._value() || new CustomDate('', '', '');
      untracked(() => this.parts.setValue(value));
    });

    this.parts.statusChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      this.stateChanges.next();
    });

    this.parts.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      const date = this.parts.valid
        ? new CustomDate(
            this.parts.value.day || '',
            this.parts.value.month || '',
            this.parts.value.year || '',
          )
        : null;
      this._updateValue(date);
    });

    // *******************    set date format
    const locale: string = document.documentElement.lang || navigator.language;
    const formatter = new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const sampleDate = new Date('2024-12-31');
    const formattedDate = formatter.format(sampleDate);
    const parts = formattedDate.split(this.separator);

    this.separator = formattedDate.match(/[^0-9]/)?.[0] || '';

    parts.forEach((part) => {
      if (part.length === 4)
        this.order += 'Y'; // Year
      else if (parseInt(part) > 12)
        this.order += 'D'; // Day
      else this.order += 'M'; // Month
    });
  }

  getMaxLength(control: AbstractControl): number | null {
    if (control && control.validator) {
      const validator = control.validator({} as any);

      if (validator && validator['maxlength']) {
        return validator['maxlength'].requiredLength;
      }
    }
    return null;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn() {
    if (!this._focused()) {
      this._focused.set(true);
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched.set(true);
      this._focused.set(false);
      this.onTouched();
    }
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement,
  ): void {
    if (!control.value) {
      return;
    }
    if (
      !control.errors &&
      nextElement &&
      control.value?.length === this.getMaxLength(control)
    ) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(
    control: AbstractControl,
    prevElement?: HTMLInputElement,
  ): void {
    if (!control.value) {
      return;
    }
    if (control.value?.length < 1 && prevElement) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.custom-date-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  getElementInPosition(position: number) {
    if (this.order.charAt(position) === 'Y') {
      return this.yearInput();
    }
    if (this.order.charAt(position) === 'M') {
      return this.monthInput();
    }
    if (this.order.charAt(position) === 'D') {
      return this.dayInput();
    }
    return undefined;
  }

  onContainerClick() {
    if (this.order.charAt(0) === 'Y') {
      this._focusMonitor.focusVia(this.yearInput(), 'program');
      return;
    }
    if (this.order.charAt(0) === 'M') {
      this._focusMonitor.focusVia(this.monthInput(), 'program');
      return;
    }
    if (this.order.charAt(0) === 'D') {
      this._focusMonitor.focusVia(this.dayInput(), 'program');
      return;
    }
  }

  writeValue(dateInput: CustomDate | string | null): void {
    if (typeof dateInput === 'string') {
      const date = new Date(dateInput);

      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1;
      const day = date.getUTCDate();

      dateInput = new CustomDate(
        day < 10 ? '0' + day.toString() : day.toString(),
        month < 10 ? '0' + month.toString() : month.toString(),
        year.toString(),
      );
    }

    if (
      dateInput instanceof CustomDate &&
      !(
        dateInput.day == null &&
        dateInput.month == null &&
        dateInput.year == null
      )
    ) {
      this.parts.setValue(
        { day: dateInput.day, month: dateInput.month, year: dateInput.year },
        { emitEvent: false },
      );
      this._updateValue(dateInput);
    } else {
      this.parts.reset();
      this._updateValue(null);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabledByCva.set(isDisabled);
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }

  private _updateValue(date: CustomDate | null) {
    const current = this._value();
    if (
      date === current ||
      (date?.day === current?.day &&
        date?.month === current?.month &&
        date?.year === current?.year)
    ) {
      return;
    }
    this._value.set(date);
  }
}
