import { Injectable, inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, retry } from 'rxjs/operators';
import { ErrorHandlingService } from '../../errorhandling/error-handling.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  errorHandlingService = inject(ErrorHandlingService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // Retry the request up to 3 times in case of an error
      retry(3),
      tap((response) => {
        if (response instanceof HttpResponse) {
          if (response.body.Status === 'failed') {
            // Handle failed status from API
            this.errorHandlingService.handleError(
              response.body.error,
              response.body.errorMessage,
            );
            console.log('app-error', JSON.stringify(response.body));
          }
        }
      }),
      catchError((error: any) => {
        console.log('error interceptor', error);
        if (error instanceof HttpErrorResponse) {
          // Call the error handling service to handle the HTTP error
          this.errorHandlingService.handleHttpError(error);
        }
        // Return an empty observable instead of re-throwing the error
        return of(new HttpResponse({ body: null }));
      }),
    );
  }
}
