import { Component } from '@angular/core';

@Component({
  selector: 'app-initializing',
  standalone: true,
  imports: [],
  templateUrl: './initializing.component.html',
  styleUrl: './initializing.component.scss'
})
export class InitializingComponent {

}
