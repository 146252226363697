import {
  Injectable,
  inject,
  signal,
  Signal,
  computed,
  effect,
  WritableSignal,
} from '@angular/core';
import {
  from,
  switchMap,
  timer,
  forkJoin,
  Subscription,
  catchError,
} from 'rxjs';

import { toSignal } from '@angular/core/rxjs-interop';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { backendTypes, frontendTypes, httpTypes } from '../../assets/types';
import { HeadersService } from '../httpservices/headers.service';
import { typeGuards } from '../functions/typeguards/typeguards';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private backendUrl = environment.backendUrl;
  private http = inject(HttpClient);
  private headers = inject(HeadersService);
  private currentRefreshSubscription: Subscription | null;

  // private Signals to receive the data

  private _entityTypes = signal<
    backendTypes.sqlEntityType[] | null | undefined
  >(null);

  private _dataTypes = signal<backendTypes.sqlDataType[] | null | undefined>(
    null,
  );

  refreshTimers = signal<frontendTypes.RefreshTimer[]>([
    { type: 'default', refreshTimer: timer(0, 120000) },
  ]);

  // public Signals

  entityTypes: Signal<backendTypes.sqlEntityType[] | null> = computed(
    () => this._entityTypes() || null,
  );

  dataTypes: Signal<frontendTypes.DataType[] | null> = computed(
    () => this._dataTypes() || null,
  );

  // Signals for handling periodic refreshing of settings and configuration
  refreshRates?: WritableSignal<backendTypes.sqlRefreshRates[] | null>;

  firerefreshDefault = signal(0);

  constructor() {
    console.log('Config Service started');
    this.currentRefreshSubscription = null;

    effect(() => {
      if (this.currentRefreshSubscription) {
        this.currentRefreshSubscription.unsubscribe();
      }

      const timers = this.refreshTimers();
      if (timers.length > 0) {
        this.currentRefreshSubscription = timers[0].refreshTimer.subscribe(() =>
          this.updateConfig(),
        );
      }
    });

    effect(() => {
      this.firerefreshDefault();

      this.updateConfig();
    });

    effect(() => {
      if (!this.refreshRates || !this.refreshRates()) {
        return;
      }
      this.initializeTimers();
    });
  }

  private initializeTimers() {
    // Set up new timers based on current refresh rates

    const rates = this.refreshRates!()!;
    this.refreshTimers.set(
      rates!.map((r) => {
        return { type: r.entityType, refreshTimer: timer(0, r.refreshRate) };
      }),
    );
  }

  updateConfig(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Use forkJoin to wait for all observables to complete
      forkJoin([
        this.fetchData({
          request: 'get_entity_types',
          requestType: 'read',
        }),

        this.fetchData({
          request: 'get_refresh_rates',
          requestType: 'read',
        }),

        this.fetchData({
          request: 'get_data_types',
          requestType: 'read',
        }),
      ]).subscribe({
        next: ([entityTypes, refreshRates, dataTypes]) => {
          try {
            // Process the field types
            if (entityTypes && typeGuards.isSqlEntityTypes(entityTypes)) {
              console.log('Raw field types:', entityTypes);
              if (
                JSON.stringify(entityTypes) !==
                JSON.stringify(this._entityTypes())
              ) {
                this._entityTypes.set(entityTypes);
                console.log('_fieldTypes set');
              }
            }

            // Process the refresh rates
            if (refreshRates && typeGuards.isSqlRefreshRates(refreshRates)) {
              console.log('Raw refresh rates:', refreshRates);
              if (!this.refreshRates) {
                this.refreshRates = signal(refreshRates);
                console.log('_refreshRates set');
              } else if (
                JSON.stringify(refreshRates) !==
                JSON.stringify(this.refreshRates())
              ) {
                this.refreshRates.set(refreshRates);
                console.log('_refreshRates set');
              }
            }

            // Process the field types
            if (dataTypes && typeGuards.isSqlDataTypes(dataTypes)) {
              console.log('Raw dataTypes types:', dataTypes);
              if (
                JSON.stringify(dataTypes) !== JSON.stringify(this._dataTypes())
              ) {
                this._dataTypes.set(dataTypes);
                console.log('_dataTypes set');
              }
            }

            // Resolve the promise once all data is set successfully
            resolve();
          } catch (error) {
            console.error('Error processing fetched data:', error);
            reject(error);
          }
        },
        error: (error) => {
          // Handle any error that occurred in any of the observables
          console.error('Error fetching config data:', error);
          reject(error);
        },
      });
    });
  }

  fetchData(request: httpTypes.DbRequest) {
    const headersObservable = from(this.headers.createHeaders());
    return headersObservable.pipe(
      switchMap((headers) => {
        return this.http.post<httpTypes.DbResponse>(this.backendUrl, request, {
          headers: headers,
        });
      }),
      catchError((error) => {
        throw error;
      }),
    );
  }
  ngOnDestroy() {
    if (this.currentRefreshSubscription) {
      this.currentRefreshSubscription.unsubscribe();
    }
  }
}
