import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../userservices/auth.service';
import { filter, take, map, Observable } from 'rxjs';

export const LoginGuard: CanActivateFn = (
  route,
  state,
): Observable<boolean | UrlTree> => {
  const auth = inject(AuthService);
  const router = inject(Router);

  return auth.user$.pipe(
    filter((user) => user !== undefined), // Ensure the observable emits defined user states
    take(1), // Only take the first emitted value (single value)
    map((user) => {
      if (user !== null) {
        // If a user is logged in, redirect to the dashboard
        return router.createUrlTree(['/dashboard']);
      }
      // If no user is logged in, allow access to the requested route
      console.log('no user');
      return true;
    }),
  );
};
