import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ErrorsnackComponent } from '../../components/ui-components/snackbar/errorsnack/errorsnack.component';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  snackBar = inject(MatSnackBar);

  handleError(error: any, message: string) {
    this.showErrorMessage(error, message);
  }

  handleHttpError(error: HttpErrorResponse) {
    this.showErrorMessage(error.status.toString(), error.message);
    console.log(error);

    return EMPTY;
  }

  private showErrorMessage(code: string, message: string | string[]) {
    this.snackBar.openFromComponent(ErrorsnackComponent, {
      duration: 5000,
      data: { message: message, error: code },
    });
  }
}
