import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '../userservices/auth.service';
import { firstValueFrom } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HeadersService {
  constructor(private authService: AuthService) {}

  async createHeaders(): Promise<HttpHeaders> {
    let headers = new HttpHeaders();

    try {
      // Access user$ only when createHeaders is called, ensuring it's initialized
      const user$ = this.authService.user$;
      if (!user$) {
        console.error('authService.user$ is not initialized');
        throw new Error('AuthService not ready');
      }

      // Use firstValueFrom to get the user token from the observable `user$`
      const tokenResult = await firstValueFrom(
        user$.pipe(
          filter((user) => {
            return user !== undefined && user !== null; // Ensure the user is not null or undefined
          }),
          switchMap((user) => {
            return user!.getIdTokenResult(); // Fetch token for the valid user
          }),
        ),
      );

      if (tokenResult) {
        const token = tokenResult.token;
        if (token) {
          headers = headers.set('Authorization', `Bearer ${token}`);
        }
      } else {
        console.log('No token available');
      }
    } catch (error) {
      console.error('Error creating headers:', error);
    }

    return headers;
  }
}
