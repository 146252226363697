import { Component, inject, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { DataService } from '../../services/dataservices/data.service';
import { ConfigService } from '../../services/settings and config/config.service';

import { SettingsService } from '../../services/settings and config/settings.service';
import { ConfirmDialogComponent } from '../ui-components/confirm-dialog/confirm-dialog.component';
import { FunctionsService } from '../../services/functions/functions.service';
import { backendTypes, frontendTypes } from '../../assets/types';
import { AddEntityComponent } from '../ui-components/add-entity/add-entity.component';

import { DynamicTableComponent } from '../ui-components/dynamic-table/dynamic-table.component';

import {
  editEntityContactForm,
  insertEntityContactForm,
} from '../../assets/model/data.model';

const SYSTEM_IDENTITIES = backendTypes.SYSTEM_IDENTITIES;

@Component({
  selector: 'app-contacts',
  standalone: true,
  imports: [CommonModule, DynamicTableComponent],
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss',
})
export class ContactsComponent {
  dataService = inject(DataService);
  config = inject(ConfigService);

  tableFilter: frontendTypes.EntityFilterCondition = {
    key: 'entityId',
    value: SYSTEM_IDENTITIES.table_columns_contacts,
    operator: 'equals',
  };

  tableColumns = this.dataService.getEntitiesByFilter([this.tableFilter]);

  dialog = inject(MatDialog);
  settings = inject(SettingsService);
  functions = inject(FunctionsService);

  editForm = editEntityContactForm;

  insertForm = insertEntityContactForm;

  dataFilter: frontendTypes.EntityFilterCondition = {
    key: 'entityType',
    value: SYSTEM_IDENTITIES.entityType_contact,
    operator: 'equals',
  };

  tableData = this.dataService.getEntitiesByFilter([this.dataFilter]);

  removeRow = (id: string) => {
    if (
      this.tableData()?.some(
        (contact) =>
          contact[0].entityId.toString() === id &&
          contact[0].clientId === SYSTEM_IDENTITIES.system_client_id,
      )
    ) {
      console.log('no permission to edit system fields');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: 'deleteWarning_contact',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.dataService.dbDeleteEntity(Number(id)).subscribe({
          next: (response) => {
            if (!response) {
              console.error('delete operation failed');
            }
          },
          error: (err) => {
            console.error('Delete failed:', err);
          },
        });
      } else {
        console.log('delete contact canceled');
      }
    });

    console.log('contact with id: ' + id + ' removed');
  };

  addRow = () => {
    const dialogRef = this.dialog.open(AddEntityComponent, {
      data: {
        crudType: 'insert',
        entityType: 'contact',
        entity: null,
        form: this.insertForm,
        formTitle: 'insert contact',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  };

  editRow = (id: string) => {
    const entity = this.tableData()!.find((e) => e[0].entityId === Number(id));
    if (!entity) {
      return;
    }
    if (entity[0].clientId === SYSTEM_IDENTITIES.system_client_id) {
      console.log('no permission to edit system fields');
      return;
    }

    console.log('contact with id: ' + id + ' edited');

    const dialogRef = this.dialog.open(AddEntityComponent, {
      data: {
        crudType: 'update',
        entityType: 'contact',
        entity: entity,
        form: this.editForm,
        formTitle: 'update contact',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  };

  // // upload contacts

  // onFileChange(event: any) {
  //   const file = event.target.files[0];
  //   if (file) {
  //     this.uploadFileService.readFile(file);
  //   }
  // }
}
