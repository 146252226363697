import { FocusMonitor } from '@angular/cdk/a11y';
import { AsyncPipe, JsonPipe, CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  booleanAttribute,
  computed,
  effect,
  forwardRef,
  inject,
  input,
  model,
  signal,
  untracked,
  viewChild,
  Input,
  HostBinding,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormFieldControl,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';

class Note {
  constructor(public note: string) {}
}

@Component({
  selector: 'custom-notes-input',
  styleUrl: 'notes.component.scss',
  templateUrl: 'notes.component.html',
  host: {
    '[class.custom-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
  providers: [{ provide: MatFormFieldControl, useExisting: NotesInput }],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, MatInputModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesInput
  implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy
{
  @ViewChild('noteInput') noteInput!: ElementRef<HTMLInputElement>;
  @ViewChild('textareaInput') textareaInput!: ElementRef<HTMLTextAreaElement>;

  static nextId = 0;

  ngControl = inject(NgControl, { optional: true, self: true });
  readonly parts: FormGroup<{
    note: FormControl<string | null>;
  }>;
  readonly stateChanges = new Subject<void>();
  readonly touched = signal(false);
  readonly controlType = 'custom-notes-input';
  readonly id = `custom-notes-input-${NotesInput.nextId++}`;
  readonly _userAriaDescribedBy = input<string>('', {
    alias: 'aria-describedby',
  });
  readonly _placeholder = input<string>('', { alias: 'placeholder' });
  readonly _required = input<boolean, unknown>(false, {
    alias: 'required',
    transform: booleanAttribute,
  });
  readonly _disabledByInput = input<boolean, unknown>(false, {
    alias: 'disabled',
    transform: booleanAttribute,
  });
  readonly _value = model<Note | null>(null, { alias: 'value' });
  onChange = (_: any) => {};
  onTouched = () => {};

  protected readonly _formField = inject(MAT_FORM_FIELD, {
    optional: true,
  });

  private readonly _focused = signal(false);
  private readonly _disabledByCva = signal(false);
  private readonly _disabled = computed(
    () => this._disabledByInput() || this._disabledByCva(),
  );
  private readonly _focusMonitor = inject(FocusMonitor);
  private readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  get focused(): boolean {
    return this._focused();
  }

  get empty() {
    const {
      value: { note },
    } = this.parts;

    return !note;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get userAriaDescribedBy() {
    return this._userAriaDescribedBy();
  }

  get placeholder(): string {
    return this._placeholder();
  }

  get required(): boolean {
    return this._required();
  }

  get disabled(): boolean {
    return this._disabled();
  }

  get value(): string | null {
    if (!this._value || !this._value() || !this._value()?.note) {
      return '';
    }

    return this._value()!.note;
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched();
  }
  constructor() {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.parts = inject(FormBuilder).group({
      note: ['', [Validators.maxLength(1000)]],
    });

    effect(() => {
      // Read signals to trigger effect.
      this._placeholder();
      this._required();
      this._disabled();
      this._focused();
      // Propagate state changes.
      untracked(() => this.stateChanges.next());
    });

    effect(() => {
      if (this._disabled()) {
        untracked(() => this.parts.disable());
      } else {
        untracked(() => this.parts.enable());
      }
    });

    effect(() => {
      const value = this._value() || new Note('');
      untracked(() => this.parts.setValue(value));
    });

    this.parts.statusChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      this.stateChanges.next();
    });

    this.parts.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      const note = this.parts.valid
        ? new Note(this.parts.value.note || '')
        : null;
      this._updateValue(note);
    });
  }

  isEditMode = false;
  noteContent = '';

  _showEditMode(): void {
    this.isEditMode = true;
    const textLength = this.parts.get('note')?.value?.length || 0;
    setTimeout(() => {
      this.textareaInput.nativeElement.focus();
      this.textareaInput.nativeElement.setSelectionRange(
        textLength,
        textLength,
      );
    }, 0);
  }

  hideEditMode(): void {
    this.isEditMode = false;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn() {
    if (!this._focused()) {
      this._focused.set(true);
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched.set(true);
      this._focused.set(false);
      this.onTouched();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.custom-notes-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {}

  writeValue(note: Note | string | null): void {
    // If `note` is a string, convert it to a `Note` instance
    if (typeof note === 'string') {
      note = new Note(note);
    }

    // Update the internal FormGroup `parts` with the `note` content
    if (note instanceof Note && note.note !== null) {
      this.parts.setValue({ note: note.note }, { emitEvent: false });
      this._updateValue(note);
    } else {
      this.parts.reset();
      this._updateValue(null);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabledByCva.set(isDisabled);
  }

  _handleInput(): void {
    if (this.parts.get('note') && this.parts.get('note')?.value) {
      if (this.parts.get('note')!.value!.length > 12 && !this.isEditMode) {
        this._showEditMode();
      }
    }
    this.onChange(this.value);
  }

  private _updateValue(note: Note | null) {
    const current = this._value();
    if (note === current || note?.note === current?.note) {
      return;
    }
    this._value.set(note);
  }

  // ngOnInit(): void {
  //   if (this.ngControl?.control?.value) {
  //     console.log('ngcontrol has value: ', this.ngControl?.control?.value);
  //     this.writeValue(this.ngControl.control.value);
  //   } else {
  //     console.log('ngcontrol has no initial value');
  //   }
  // }
}
