import { Component, inject, Inject } from '@angular/core';

import {
  MatDialogModule,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

/** import {CancelComponent} from '../buttons/cancel/cancel.component' */

@Component({
  selector: 'app-confirm-dialogue',
  standalone: true,
  imports: [MatDialogModule, TranslateModule, MatButtonModule],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
})
export class ConfirmDialogComponent {
  translate = inject(TranslateService);

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public warningMessage: string,
  ) {}
}
