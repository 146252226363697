<BR />
<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="" #input />
</mat-form-field>

<!-- <input type="file" (change)="onFileChange($event)" accept=".csv" /> -->
<!-- style="display: table" -->

<table
  mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8"
  style="display: table"
>
  <!-- *****************  deleteColumn *********************-->
  <ng-container
    *ngIf="tableSettings().removeButton"
    matColumnDef="deleteColumn"
  >
    <th mat-header-cell *matHeaderCellDef>
      <button
        *ngIf="tableSettings().addButton"
        mat-icon-button
        (click)="addRow()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </th>
    <td mat-cell *matCellDef="let element">
      <app-close
        (click)="removeRow(element[0].entityId)"
        [enabled]="element[0].clientId != SYSTEM_IDENTITIES.system_client_id"
      ></app-close>
    </td>
  </ng-container>

  <!-- *****************  editColumn *********************-->

  <ng-container
    *ngIf="
      tableSettings().editButton ||
      (tableSettings().addButton && !tableSettings().removeButton)
    "
    matColumnDef="editColumn"
  >
    <th mat-header-cell *matHeaderCellDef>
      <button
        *ngIf="tableSettings().addButton && !tableSettings().removeButton"
        mat-icon-button
        (click)="addRow()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </th>

    <td mat-cell *matCellDef="let element">
      <app-edit
        (click)="editRow(element[0].entityId)"
        [enabled]="element[0].clientId != SYSTEM_IDENTITIES.system_client_id"
      ></app-edit>
    </td>
  </ng-container>

  <!-- *****************  cells *********************-->

  <ng-container
    [matColumnDef]="col"
    *ngFor="let col of tableSettings().dataColumns"
  >
    <th mat-header-cell *matHeaderCellDef>
      <app-data-field
        [fieldId]="col"
        [data]="null"
        [template]="'table-header'"
      ></app-data-field>
    </th>

    <td mat-cell *matCellDef="let element" class="cell-content-ellipsis">
      <app-data-field
        [fieldId]="col"
        [data]="element"
        [template]="'table-cell'"
      ></app-data-field>
    </td>
  </ng-container>

  <!-- *****************  rows *********************-->

  <tr
    mat-row
    mat-header-row
    *matHeaderRowDef="tableSettings().displayedColumns"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: tableSettings().displayedColumns"
    [class.hidden]="row.collapsed"
    class="table-row"
  ></tr>

  <!--  Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">
      No data matching the filter "{{ input.value }}"
    </td>
  </tr>
</table>
