<div
  role="group"
  class="custom-notes-input-container"
  [formGroup]="parts"
  [attr.aria-labelledby]="_formField ? _formField.getLabelId() : null"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut($event)"
  (click)="onContainerClick()"
>
  <!-- Preview mode input -->
  <input
    class="custom-notes-preview-element"
    formControlName="note"
    aria-label="Notes"
    maxLength="1000"
    [hidden]="isEditMode"
    (click)="_handleInput()"
    (input)="_handleInput()"
    aria-autocomplete="none"
    #noteInput
  />

  <!-- Edit mode textarea with autofocus -->
  <textarea
    matInput
    class="custom-notes-input"
    (focusout)="hideEditMode()"
    (input)="_handleInput()"
    [hidden]="!isEditMode"
    formControlName="note"
    aria-label="Edit Notes"
    maxLength="1000"
    aria-autocomplete="none"
    #textareaInput
  ></textarea>

  <!-- Close button for edit mode -->
  <button *ngIf="isEditMode" class="close-btn" (click)="hideEditMode()">
    Close
  </button>
</div>
