<div
  role="group"
  class="custom-phone-input-container"
  [formGroup]="parts"
  [attr.aria-labelledby]="_formField ? _formField.getLabelId() : null"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut($event)"
  (click)="onContainerClick()"
>
  <input
    class="custom-phone-input-element"
    formControlName="country"
    size="6"
    maxLength="6"
    aria-label="Country code"
    (input)="_handleInput(parts.controls.country, subscriber)"
    #country
    aria-autocomplete="none"
  />
  <span class="custom-phone-input-spacer">&ndash;</span>

  <input
    class="custom-phone-input-element"
    formControlName="subscriber"
    maxLength="13"
    size="13"
    aria-label="Subscriber number"
    (input)="_handleInput(parts.controls.subscriber)"
    (keyup.backspace)="autoFocusPrev(parts.controls.subscriber, country)"
    #subscriber
    aria-autocomplete="none"
  />
</div>
