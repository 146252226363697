<mat-card class="auth-card-container">
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>
  <mat-icon>thumb_up</mat-icon>
  <mat-card-content>
    <p>
      We sent an email to <strong>{{ email }}</strong
      >. Please click on the link in the email to to complete sign-in.
    </p>
    <br />
    <p>You can close this page now.</p>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button type="button" (click)="backToLogin()">BACK</button>
  </mat-card-actions>
</mat-card>
