import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';


@Component({
  selector: 'app-errorsnack',
  standalone: true,
  imports: [],
  templateUrl: './errorsnack.component.html',
  styleUrl: './errorsnack.component.scss',
})
export class ErrorsnackComponent {
  error: string;
  messages: string[];
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { error: string; message: string | string[] }
  ) {
    this.error = data.error;
    this.messages =
      data.message instanceof Array ? data.message : [data.message];
    console.log('data in snackbar' + JSON.stringify(data));
  }
}
