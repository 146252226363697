import { backendTypes, httpTypes } from '../../../assets/types';
import { NavigationEnd } from '@angular/router';

export const typeGuards = {
  isSqlUserArray,
  isSqlRefreshRates,
  isNavigationEnd,
  isSqlEntityTypes,
  isSqlDataField,
  isSqlEntity,
  isSqlDataTypes,
  isSqlDataFieldArray,
  isHttpUpdateDataFieldInput,
  isSqlDataFieldId,
  isSqlEntityId,
  isSqlUserList,
  isSqlTimeReg,
};

function isSqlDataFieldId(
  response: any,
): response is backendTypes.sqlDataFieldId {
  return typeof response.dataFieldId === 'number';
}

function isSqlEntityId(response: any): response is backendTypes.sqlEntityId {
  return typeof response.entityId === 'number';
}

function isArray(response: any): response is any[] {
  return Array.isArray(response);
}

function isSqlUserArray(response: any): response is backendTypes.sqlUser[] {
  return isArray(response) && response.length > 0 && 'Alias' in response[0];
}

function isSqlUserList(
  response: any,
): response is backendTypes.sqlUserListItem[] {
  return isArray(response) && response.length > 0 && 'alias' in response[0];
}

function isSqlRefreshRates(
  response: any,
): response is backendTypes.sqlRefreshRates[] {
  return (
    isArray(response) &&
    response.length > 0 &&
    'entityType' in response[0] &&
    'refreshRate' in response[0]
  );
}

function isSqlEntityTypes(
  response: any,
): response is backendTypes.sqlEntityType[] {
  return (
    isArray(response) &&
    response.length > 0 &&
    'id' in response[0] &&
    'entityType' in response[0] &&
    'entityName' in response[0] &&
    'description' in response[0]
  );
}

function isSqlDataTypes(response: any): response is backendTypes.sqlDataType[] {
  return (
    isArray(response) &&
    response.length > 0 &&
    'id' in response[0] &&
    'name' in response[0] &&
    'description' in response[0] &&
    'metaData' in response[0]
  );
}

function isSqlEntity(response: any): response is backendTypes.sqlEntity {
  return (
    isArray(response) &&
    response.length > 0 &&
    'entityId' in response[0] &&
    'entityType' in response[0] &&
    'dataFieldId' in response[0] &&
    'data_value' in response[0]
  );
}

function isSqlTimeReg(response: any): response is backendTypes.sqlTimeReg {
  return (
    isArray(response) &&
    response.length > 0 &&
    'timeId' in response[0] &&
    'dataFieldId' in response[0] &&
    'data_value' in response[0]
  );
}

function isSqlDataField(response: any): response is backendTypes.sqlDataField {
  return (
    'id' in response &&
    'name' in response &&
    'label' in response &&
    'description' in response &&
    'dataType' in response &&
    'clientId' in response
  );
}

function isHttpUpdateDataFieldInput(
  response: any,
): response is httpTypes.UpdateDataFieldInput {
  return (
    'id' in response &&
    'name' in response &&
    'label' in response &&
    'description' in response
  );
}

function isSqlDataFieldArray(
  response: any,
): response is backendTypes.sqlDataField[] {
  return (
    isArray(response) &&
    response.length > 0 &&
    'id' in response[0] &&
    'name' in response[0] &&
    'label' in response[0] &&
    'description' in response[0] &&
    'dataType' in response[0] &&
    'clientId' in response[0]
  );
}

function isNavigationEnd(event: any): event is NavigationEnd {
  return event instanceof NavigationEnd;
}
