import { Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-checkmail',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIcon,
  ],
  templateUrl: './checkmail.component.html',
  styleUrl: './checkmail.component.scss',
})
export class CheckmailComponent {
  title = 'Sign-in email sent';
  router = inject(Router);

  email = window.localStorage.getItem('emailForSignIn');

  backToLogin() {
    this.router.navigate(['login']);
  }
}
