<button mat-icon-button (click)="matSelect.open()">
  <mat-icon
    ><img
      src="{{ selectedLanguage().flag }}"
      alt="{{ selectedLanguage().name }}"
  /></mat-icon>
</button>

<!-- Hidden mat-select field but still functional -->
<mat-form-field class="hidden">
  <mat-select
    #matSelect
    [value]="selectedLanguage()"
    (selectionChange)="changeLanguage($event.value.code)"
  >
    @for (language of supportedLanguages; track language) {
      <mat-option [value]="language">
        <img
          src="{{ language.flag }}"
          class="flag-icon"
          alt="{{ language.name }}"
        />{{ language.name }}</mat-option
      >
    }
  </mat-select>
</mat-form-field>
