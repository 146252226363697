<BR />

<ng-container *ngIf="tableColumns(); else noTable">
  <app-dynamic-table
    [tableColumns]="tableColumns()![0] || null"
    [tableInput]="tableData()"
    [buttonSettings]="[true, true, true]"
    [tableFunctions]="[addRow, editRow, removeRow]"
  ></app-dynamic-table>
</ng-container>
<ng-template #noTable>
  <p>no columns to display</p>
</ng-template>
