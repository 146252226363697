import { Injectable, inject, signal, computed, Signal } from '@angular/core';
import { switchMap, catchError, from, tap, map, Observable } from 'rxjs';

// import { ConfigService } from '../settings and config/config.service';
import { HttpService } from '../httpservices/http.service';
import { backendTypes, frontendTypes, httpTypes } from '../../assets/types';

import { UserService } from '../userservices/user.service';
import { SettingsService } from '../settings and config/settings.service';
import { typeGuards } from '../functions/typeguards/typeguards';

@Injectable({
  providedIn: 'root',
})
export class TimeRegService {
  // private config = inject(ConfigService);
  private settings = inject(SettingsService);
  private http = inject(HttpService);
  private user = inject(UserService);
  private _timeReg = signal<frontendTypes.TimeData | null>(null);

  groupRegistrations(
    timeregs: backendTypes.sqlTimeReg,
  ): frontendTypes.TimeData {
    const groupedEntities = Object.values(
      timeregs.reduce(
        (acc, item) => {
          // If there's no array for this entityId yet, create one
          if (!acc[item.timeId]) {
            acc[item.timeId] = [];
          }
          // Add the item to the respective entityId group
          acc[item.timeId].push(item);
          return acc;
        },
        {} as { [key: number]: backendTypes.timeData[] },
      ), // Using a dictionary to accumulate grouped items
    );

    return groupedEntities;
  }

  constructor() {
    // **************** get time registrations ****************

    this.dbGetMyRegistrations().subscribe((timeRegs) => {
      if (timeRegs && typeGuards.isSqlTimeReg(timeRegs)) {
        const groupedData = this.groupRegistrations(timeRegs);

        this._timeReg.set(groupedData);
      }
    });
  }

  myTimeRegistrations = computed(() => {
    if (!this._timeReg()) {
      return null;
    }
    const myTimes = this._timeReg()?.filter(
      (time) => time[0].userId === this.user.user()?.Id,
    );
    return myTimes || null;
  });

  dbGetMyRegistrations(): Observable<backendTypes.sqlTimeReg | null> {
    return this.http
      .fetchData({
        request: 'get_time_by_user',
        requestType: 'read',
      })
      .pipe(
        map((time) => {
          if (typeGuards.isSqlTimeReg(time)) {
            return time;
          }
          return null;
        }),
      );
  }

  dbInsertTimeReg(registration: httpTypes.InsertTimeRegInput): Observable<any> {
    return this.http
      .fetchData({
        request: 'insert_timereg',
        requestType: 'write',
        parameters: registration,
      })
      .pipe(
        map((response) => {
          if (response && typeGuards.isSqlTimeReg(response)) {
            if (!this._timeReg()) {
              this._timeReg.set([response]);
            } else {
              this._timeReg.update((timereg) => [...timereg!, response]);
            }
            return response;
          }
          return null;
        }),
      );
  }
}
